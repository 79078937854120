import { isChoiceProduct, isGroupProduct, ProductType, isCartProductValid, haveIncludedProducts, isCartFreeProductsValid, selectIsAuthenticated, freeChoicesLeft, isStockValid, BusinessType, getElementScaleGroup, isB2C, isB2B, } from "common";
import React, { useEffect, useRef, useState } from "react";
import { Form, FieldArray, useFormikContext } from "formik";
import { ProductSteps } from "../components/ProductSteps";
import { getActiveStep, getProductsSteps } from "services/utils/products.steps";
import { AddToCartButton } from "../components/AddToCartButton";
import { Modal } from "components/Modal/Modal";
import { ProductFreeChoices } from "../components/ProductFreeChoices";
import { ProductQuantity } from "../components/ProductQuantity";
import { ProductDetailFooter } from "../components/ProductDetailFooter";
import { ProductPackSelector } from "../components/ProductPackSelector";
import { ProductChoicesSelector } from "../components/ProductChoicesSelector";
import { Button } from "components/Form";
import { ProductsIncluded } from "../components/ProductsIncluded";
import { ChoicesLeft } from "../components/ChoicesLeft";
import { ProductPrice } from "../components/ProductPrice";
import { isMobile } from 'react-device-detect';
import { useSelector } from "react-redux";
import Login from "containers/Login/Login";
import { Link } from "react-router-dom";
import { selectProductIndexToModify } from "store/cart/cartSelectors";
import { Angle } from "components/Angle/Angle";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { FormattedMessage, useIntl } from "react-intl";
export const ProductForm = ({ product, toggleModal, modalId, setReviewId }) => {
    var _a, _b, _c;
    const { values, submitForm, setFieldValue } = useFormikContext();
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const ref = useRef(null);
    const [AuthModal] = useState('authModal');
    const productIndexToModify = useSelector(selectProductIndexToModify);
    const profile = useSelector(selectProfileInfos);
    const intl = useIntl();
    console.log(isB2C(profile));
    let isOrderable = false;
    if (isB2C(product)) {
        isOrderable = isB2C(profile) ? true : false;
    }
    if (isB2B(product)) {
        isOrderable = !isB2C(profile) ? true : false;
    }
    if (!isAuthenticated) {
        isOrderable = true;
    }
    useEffect(() => {
        var _a;
        setReviewId(((_a = values.element) === null || _a === void 0 ? void 0 : _a.reviewId) || product.reviewId);
    }, [values]);
    useEffect(() => {
        if (values.type === ProductType.SCALE_GROUP)
            setFieldValue('element', getElementScaleGroup(values, product));
    }, [values.choices]);
    const scrollToChoices = () => {
        if ((values === null || values === void 0 ? void 0 : values.type) === ProductType.CHOICE_GROUP || ProductType.SCALE_GROUP && isMobile) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    };
    return (React.createElement(Form, { className: "qty-number" },
        React.createElement("div", { className: "stepers" },
            isOrderable &&
                React.createElement(ProductSteps, { product: values }),
            isAuthenticated && isOrderable &&
                React.createElement(React.Fragment, null,
                    (isGroupProduct(values) && values.type !== ProductType.SCALE_GROUP) &&
                        React.createElement("div", { className: "packer" },
                            React.createElement(ProductPackSelector, { onChange: scrollToChoices, product: product }),
                            React.createElement(Angle, { step: '0', isActive: true })),
                    (product.type === ProductType.SIMPLE_PRODUCT && ((_a = product === null || product === void 0 ? void 0 : product.businessType) === null || _a === void 0 ? void 0 : _a.includes(profile === null || profile === void 0 ? void 0 : profile.businessType))) && React.createElement(ProductQuantity, null),
                    React.createElement("div", { className: "add-to-cart" },
                        React.createElement("p", null, getProductsSteps(values)[getActiveStep(values) - 1].description),
                        React.createElement("div", { className: "price" },
                            React.createElement(ChoicesLeft, { product: values }),
                            React.createElement(ProductPrice, { product: product, productCart: values }),
                            product.tag &&
                                React.createElement(AddToCartButton, { editMode: typeof productIndexToModify === 'number', product: values.type === ProductType.SCALE_GROUP ? Object.assign(Object.assign({}, values), { element: getElementScaleGroup(values, product) }) : values, prod: product, toggleModal: () => toggleModal(modalId), isAuthenticated: isAuthenticated })))),
            !isAuthenticated && ((_b = product === null || product === void 0 ? void 0 : product.businessType) === null || _b === void 0 ? void 0 : _b.includes(BusinessType.B2C)) &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "pack-selector not-connected" },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "Produit \u00E0 destination des particuliers uniquement, merci de vous connecter pour passer commande.", defaultMessage: "Produit \u00E0 destination des particuliers uniquement, merci de vous connecter pour passer commande." }))),
                    React.createElement("div", { className: "price", style: { width: '100%', textAlign: 'center' } },
                        React.createElement("br", null),
                        React.createElement(ProductPrice, { product: product, productCart: values }))),
            !isAuthenticated && ((_c = product === null || product === void 0 ? void 0 : product.businessType) === null || _c === void 0 ? void 0 : _c.includes(BusinessType.B2B)) &&
                React.createElement("div", { className: "pack-selector not-connected" },
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Pour consulter les tarifs, vous devez \u00EAtre un(e) professionnel(le) enregistr\u00E9(e) puis connect\u00E9(e)", defaultMessage: "Pour consulter les tarifs, vous devez \u00EAtre un(e) professionnel(le) enregistr\u00E9(e) puis connect\u00E9(e)" })),
                    product.tag &&
                        React.createElement(AddToCartButton, { product: values, prod: product, toggleModal: () => toggleModal(AuthModal), isAuthenticated: isAuthenticated }))),
        React.createElement(Modal, { id: modalId },
            React.createElement("div", { className: "included-products" },
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "Mes produits offerts", defaultMessage: "Mes produits offerts" })),
                React.createElement("div", { className: "included-details" },
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Veuillez choisir vos produits offerts", defaultMessage: "Veuillez choisir vos produits offerts" }),
                        "."),
                    freeChoicesLeft(values) > 0 && (React.createElement("span", null,
                        freeChoicesLeft(values),
                        " ",
                        React.createElement(FormattedMessage, { id: "restant(s)", defaultMessage: "restant(s)" })))),
                React.createElement("div", { className: "included-list" },
                    React.createElement(FieldArray, { name: isGroupProduct(values) ? 'element.freeChoices.ids' : 'freeChoices.ids' }, ({ replace, form }) => (React.createElement(ProductFreeChoices, { form: form, replace: replace })))),
                product.tag &&
                    React.createElement(Button, { onClick: submitForm, buttonName: !isStockValid(values) ? intl.formatMessage({ id: "Produit indisponible", defaultMessage: "Produit indisponible" }) : (typeof productIndexToModify === 'number' ? intl.formatMessage({ id: "Modifier le produit", defaultMessage: "Modifier le produit" }) : intl.formatMessage({ id: "Ajouter au panier", defaultMessage: "Ajouter au panier" })), className: "orange cart", disabled: !(isCartProductValid(values, product) && isCartFreeProductsValid(values)) })),
            React.createElement("div", { className: "free-products" },
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Produits inclus dans le pack", defaultMessage: "Produits inclus dans le pack" })),
                haveIncludedProducts(values) && React.createElement(ProductsIncluded, { product: values }))),
        React.createElement(Modal, { id: AuthModal },
            React.createElement("div", { className: "included-products login-modal" },
                React.createElement(Login, { modalId: AuthModal }),
                React.createElement("small", null,
                    React.createElement(Link, { to: "/authentification" },
                        React.createElement(FormattedMessage, { id: "Cr\u00E9er un compte", defaultMessage: "Cr\u00E9er un compte" }))))),
        ((product.type === ProductType.SCALE_GROUP || product.type === ProductType.CHOICE_PRODUCT) && isAuthenticated && product.scalePriceInformations && !!profile && profile.businessType === BusinessType.B2B) &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "scale-price" },
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: product.scalePriceInformations } }),
                    React.createElement(Link, { to: "#tarifs" }, "Voir les tarifs"))),
        isAuthenticated && !isOrderable && isB2B(product) &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "pack-selector not-connected" },
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Produit \u00E0 destination des professionnels uniquement.", defaultMessage: "Produit \u00E0 destination des professionnels uniquement." }))),
                React.createElement("div", { ref: ref }, isChoiceProduct(values) && React.createElement(ProductChoicesSelector, { toggleModal: () => console.log('not authorized'), AuthModal: '', isAuthenticated: false }))),
        isAuthenticated && !isOrderable && isB2C(product) &&
            React.createElement("div", { className: "pack-selector not-connected" },
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Produit \u00E0 destination des particuliers uniquement.", defaultMessage: "Produit \u00E0 destination des particuliers uniquement." }))),
        isOrderable &&
            React.createElement("div", { ref: ref }, isChoiceProduct(values) && React.createElement(ProductChoicesSelector, { toggleModal: () => toggleModal(AuthModal), AuthModal: AuthModal, isAuthenticated: isAuthenticated })),
        product.tag && isOrderable &&
            React.createElement(ProductDetailFooter, { editMode: typeof productIndexToModify === 'number', toggleModal: () => toggleModal(!isAuthenticated ? AuthModal : modalId), isAuthenticated: isAuthenticated, product: values, prod: product })));
};
