import { logoutAttempt } from "common";
import { Button } from "components/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersAttempt } from "store/account/orders/ordersActions";
import { selectOrders } from "store/account/orders/ordersSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function Factures() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const orders = useSelector(selectOrders);
    useEffect(() => {
        dispatch(fetchOrdersAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos factures", defaultMessage: "Vos factures" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }))),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "commandes" },
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "Historique de vos factures", defaultMessage: "Historique de vos factures" }),
                    "."),
                React.createElement("div", { className: "orders-list" }, orders.length > 0 && orders.reverse().map((order) => {
                    var _a, _b;
                    return React.createElement("div", { key: order.orderId, className: "order-item" },
                        React.createElement("div", { className: "order-toggle", style: { 'width': '100%' } },
                            React.createElement(FormattedMessage, { id: "Commande n\u00B0", defaultMessage: "Commande n\u00B0" }),
                            React.createElement("span", null,
                                " ",
                                order.orderId),
                            React.createElement(FormattedMessage, { id: "du", defaultMessage: "du" }),
                            React.createElement("span", null,
                                " ",
                                order.createdAt),
                            ":"),
                        ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined ? React.createElement("div", null,
                            React.createElement("span", null, "\u21B3"),
                            " ",
                            React.createElement("a", { href: (_b = order === null || order === void 0 ? void 0 : order.billing) === null || _b === void 0 ? void 0 : _b.viewUrl, target: "_blank" },
                                React.createElement(FormattedMessage, { id: "T\u00E9l\u00E9charger ma facture", defaultMessage: "T\u00E9l\u00E9charger ma facture" }),
                                " ")) : '');
                }))))));
}
export default Factures;
