import React from "react";
import { Angle } from "components/Angle/Angle";
import "./Step.scss";
import { isMobile, isTablet } from "react-device-detect";
export function Step({ disabled, children, title, step, activeStep, setActiveStep }) {
    const isStepActive = step === activeStep;
    return (React.createElement("div", { className: "adresses adress-step" },
        isMobile && !isTablet && React.createElement("span", { className: "step is-hidden-mobile" },
            React.createElement("span", null, step)),
        React.createElement("div", { className: `adresse-item ${isStepActive ? 'open' : ''}` },
            React.createElement("div", { className: "adresse-toggle" },
                React.createElement("span", { className: "step is-hidden-desktop" },
                    React.createElement("span", null, step)),
                React.createElement("strong", null, title),
                React.createElement(Angle, { disabled: disabled, isActive: isStepActive, setActiveStep: setActiveStep, step: step })),
            React.createElement("div", { className: "adresse-infos" }, children))));
}
