import React from "react";
import { PudoListItem } from "./PudoListItem";
import { FormattedMessage } from "react-intl";
export function PudosList({ pudos }) {
    return (React.createElement("div", null,
        React.createElement("p", null,
            React.createElement("br", null),
            "2. ",
            React.createElement(FormattedMessage, { id: "Veuillez choisir votre point relais : ", defaultMessage: "Veuillez choisir votre point relais : " })),
        pudos.map(pudo => (React.createElement(PudoListItem, { key: pudo._id, pudo: pudo })))));
}
