import { logoutAttempt, selectErrors, selectPending } from 'common';
import { Button } from 'components/Form';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAddressAttempt, deleteAddressAttempt, updateAddressAttempt } from 'store/account/addresses/addressActions';
import "./Adresses.scss";
import { AddressForm } from 'components/AddressForm/AddressForm';
import { selectAddresses, selectAddressUpdated } from 'store/account/profile/profileSelectors';
import useStep from 'shared/hooks/useStep';
import { Angle } from 'components/Angle/Angle';
import { Formik } from 'formik';
import { CheckMarkAnimation } from 'components/Animations/CheckMarkAnimation';
import { FormattedMessage, useIntl } from "react-intl";
const initialValues = {
    name: '',
    firstname: '',
    lastname: '',
    salon: '',
    address: '',
    country: '',
    city: '',
    postalCode: '',
    phone: '',
    phoneFix: ''
};
function Adresses() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useStep();
    const addressUpdated = useSelector(selectAddressUpdated);
    const apiErrors = useSelector(activeStep === ''
        ? selectErrors('CREATE_ADDRESS')
        : selectErrors('UPDATE_ADDRESS'));
    const addresses = useSelector(selectAddresses);
    const pending = useSelector(selectPending('UPDATE_ADDRESS'));
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos adresses", defaultMessage: "Vos adresses" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }))),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "adresses container" },
                React.createElement("div", { className: `adresse-item ${activeStep === '' ? 'open' : ''}` },
                    React.createElement("div", { className: "adresse-toggle" },
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: "Nouvelle Adresse", defaultMessage: "Nouvelle Adresse" })),
                        React.createElement(Button, { onClick: () => setActiveStep(''), className: "black-white", buttonName: intl.formatMessage({ id: "Ajouter", defaultMessage: "Ajouter" }) })),
                    React.createElement("div", { className: "adresse-infos" },
                        React.createElement(Formik, { validateOnChange: true, initialValues: initialValues, enableReinitialize: true, onSubmit: async (values, { resetForm }) => {
                                dispatch(createAddressAttempt(values, (err, res) => {
                                    if (res) {
                                        setActiveStep(res._id);
                                        resetForm({ values: initialValues });
                                    }
                                }));
                            } },
                            React.createElement(AddressForm, { apiErrors: apiErrors },
                                React.createElement("div", { className: "adresse-actions" },
                                    React.createElement(Button, { buttonName: intl.formatMessage({ id: "Envoyer", defaultMessage: "Envoyer" }), className: "black-white" })))))),
                addresses.length > 0 && addresses.map((address) => {
                    return React.createElement("div", { key: address._id, className: `adresse-item ${activeStep === address._id ? 'open' : ''}` },
                        React.createElement("div", { onClick: () => setActiveStep(address._id), className: "adresse-toggle" },
                            React.createElement("strong", null, address.name),
                            React.createElement(Angle, { isActive: activeStep === address._id, setActiveStep: setActiveStep, step: address._id })),
                        React.createElement("div", { className: "adresse-infos" },
                            React.createElement(Formik, { validateOnChange: true, initialValues: address, enableReinitialize: true, onSubmit: async (values) => dispatch(updateAddressAttempt(values)) },
                                React.createElement(AddressForm, { apiErrors: apiErrors },
                                    React.createElement("div", { className: "adresse-actions" },
                                        React.createElement(Button, { type: "button", className: "black-white", onClick: () => dispatch(deleteAddressAttempt(address._id)), buttonName: intl.formatMessage({ id: "Supprimer", defaultMessage: "Supprimer" }) }),
                                        React.createElement(Button, { loading: pending, buttonName: intl.formatMessage({ id: "Modifier", defaultMessage: "Modifier" }), className: "orange" }))))));
                }))),
        React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "La modification de votre adresse a bien été enregistrée", defaultMessage: "La modification de votre adresse a bien été enregistrée." }), isActive: addressUpdated })));
}
export default Adresses;
