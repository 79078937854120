import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import useModal from "shared/hooks/useModal";
import { registerModal, unregisterModal } from "store/modal/modalActions";
import "./Modal.scss";
export const Modal = ({ id, children }) => {
    const { isModalOpen, closeModal } = useModal();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(registerModal(id));
        return () => {
            dispatch(unregisterModal(id));
        };
    }, []);
    return isModalOpen(id)
        ? createPortal(React.createElement("div", { className: "modal-backdrop", onClick: (event) => event.target.className === 'modal-backdrop' && closeModal(id) },
            React.createElement("div", { className: "modal-container" },
                children,
                React.createElement("button", { onClick: () => closeModal(id) },
                    React.createElement(FormattedMessage, { id: "Fermer", defaultMessage: "Fermer" })))), document.body)
        : null;
};
