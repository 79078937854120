var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Field } from 'formik';
import { useIntl } from "react-intl";
function Select(_a) {
    var { returnKey = true, defaultLabel, label, options, onChange, displayKey, valueKey, name, defaultValue } = _a, rest = __rest(_a, ["returnKey", "defaultLabel", "label", "options", "onChange", "displayKey", "valueKey", "name", "defaultValue"]);
    const intl = useIntl();
    return (React.createElement(Field, { name: name }, ({ field, form }) => {
        var _a;
        return React.createElement("label", Object.assign({ className: "input-label" }, rest),
            label,
            React.createElement("select", Object.assign({}, rest, { value: typeof field.value === 'object' ? (_a = field.value) === null || _a === void 0 ? void 0 : _a[valueKey] : field.value, onChange: (e) => {
                    const value = !returnKey
                        ? options.find(option => option._id === e.target.value)
                        : e.target.value;
                    form.setFieldValue(name, value);
                    if (onChange)
                        onChange(value);
                } }),
                React.createElement("option", { value: '' }, defaultLabel),
                options.map((value, index) => {
                    const optionValue = !!valueKey ? value[valueKey] : value;
                    const optionDisplay = !!displayKey ? value[displayKey] : value;
                    return React.createElement("option", { key: index, value: optionValue }, optionDisplay);
                })));
    }));
}
export default Select;
