import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeAttempt } from "store/home/homeActions";
import { selectHome } from "store/home/homeSelectors";
import "./Home.scss";
import Stars from "components/Stars/Stars";
import { Link } from "react-router-dom";
import ProductListItem from "containers/Products/components/ProductListItem";
import { selectRealisations } from "store/realisations/realisationsSelectors";
import { fetchRealisationsAttempt } from "store/realisations/realisationsActions";
import { Helmet } from "react-helmet";
import { Button } from "components/Form";
import { FormattedMessage, useIntl } from "react-intl";
function Home() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
    const dispatch = useDispatch();
    const intl = useIntl();
    const home = useSelector(selectHome);
    const realisations = useSelector(selectRealisations);
    useEffect(() => {
        var _a, _b, _c;
        dispatch(fetchHomeAttempt());
        dispatch(fetchRealisationsAttempt());
        try {
            if (typeof ((_a = window) === null || _a === void 0 ? void 0 : _a.yotpo) !== "undefined") {
                (_c = (_b = window) === null || _b === void 0 ? void 0 : _b.yotpo) === null || _c === void 0 ? void 0 : _c.initWidgets();
            }
        }
        catch (err) {
            console.log('initWidget - error', err);
        }
    }, [dispatch]);
    return (React.createElement("div", { className: "home is-fullhd container" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        React.createElement("div", { className: "home-image-container", style: { 'backgroundImage': `url(${home.file})` } },
            React.createElement("article", { className: "home-image-window", style: { 'backgroundImage': `url(${home.file})` } },
                React.createElement("header", { className: "home-image-content" },
                    !!home.pretitle && React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: home === null || home === void 0 ? void 0 : home.pretitle, defaultMessage: home.pretitle })),
                    !!home.title && React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: home === null || home === void 0 ? void 0 : home.title, defaultMessage: home.title })),
                    !!home.buttonLink && !!home.buttonContent &&
                        React.createElement(Link, { to: home.buttonLink },
                            React.createElement(Button, { type: "button", buttonName: home.buttonContent, className: "orange" }))))),
        !!home.productTitle && React.createElement("h1", { className: "home-section-title" },
            React.createElement(FormattedMessage, { id: home.productTitle, defaultMessage: home.productTitle })),
        React.createElement("div", { className: "home-products-list columns is-multiline" }, (_a = home.products) === null || _a === void 0 ? void 0 : _a.map((product) => React.createElement("div", { className: "column is-one-third", key: product === null || product === void 0 ? void 0 : product._id },
            React.createElement(ProductListItem, { path: `/nos-produits/${product.slug}`, product: product })))),
        React.createElement("div", { className: "kust container" },
            React.createElement("img", { className: "logo", src: "./assets/kust-logo.png", alt: "logo kust" }),
            React.createElement("h2", null,
                React.createElement(FormattedMessage, { id: "Tous nos produits sont disponibles pour les coiffeurs professionnels membre de Kust colorations, poudres, patines, shampoings et plus ci-contre", defaultMessage: "Tous nos produits sont disponibles pour les coiffeurs professionnels membre de Kust : <strong>colorations, poudres, patines, shampoings</strong> et plus ci-contre...", values: { strong: chunks => React.createElement("strong", null, chunks) } })),
            React.createElement(Link, { to: "/nos-produits" },
                React.createElement(Button, { type: "button", buttonName: intl.formatMessage({ id: "Tous les produits", defaultMessage: "Tous les produits" }), className: "orange" }))),
        React.createElement("h1", { className: "home-section-title inverse" },
            React.createElement(FormattedMessage, { id: "Les r\u00E9alisations avec Kust", defaultMessage: "Les r\u00E9alisations avec Kust" })),
        React.createElement("div", { className: "mansory tile is-ancestor container" },
            React.createElement("div", { className: "tile is-parent" }, realisations.length > 0 &&
                React.createElement("article", { className: "is-child is-12", style: { 'backgroundImage': `url(${(_b = realisations[0]) === null || _b === void 0 ? void 0 : _b.file})` } },
                    React.createElement("a", { href: `${(_c = realisations[0]) === null || _c === void 0 ? void 0 : _c.link}`, target: "_blank" },
                        React.createElement("div", { className: "contenu" },
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: (_d = realisations[0]) === null || _d === void 0 ? void 0 : _d.review, defaultMessage: (_e = realisations[0]) === null || _e === void 0 ? void 0 : _e.review })),
                            React.createElement(Stars, { realisations: realisations[0] }))))),
            React.createElement("div", { className: "tile is-ancestor container" },
                React.createElement("div", { className: "tile is-vertical is-6" },
                    React.createElement("div", { className: "tile" },
                        React.createElement("div", { className: "is-parent is-vertical" },
                            realisations.length > 1 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_f = realisations[1]) === null || _f === void 0 ? void 0 : _f.file})` } },
                                    React.createElement("a", { href: `${(_g = realisations[1]) === null || _g === void 0 ? void 0 : _g.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_h = realisations[1]) === null || _h === void 0 ? void 0 : _h.review, defaultMessage: (_j = realisations[1]) === null || _j === void 0 ? void 0 : _j.review })),
                                            React.createElement(Stars, { realisations: realisations[1] })))),
                            realisations.length > 2 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_k = realisations[2]) === null || _k === void 0 ? void 0 : _k.file})` } },
                                    React.createElement("a", { href: `${(_l = realisations[2]) === null || _l === void 0 ? void 0 : _l.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_m = realisations[2]) === null || _m === void 0 ? void 0 : _m.review, defaultMessage: (_o = realisations[2]) === null || _o === void 0 ? void 0 : _o.review })),
                                            React.createElement(Stars, { realisations: realisations[2] }))))),
                        React.createElement("div", { className: "is-parent is-vertical" },
                            realisations.length > 3 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_p = realisations[3]) === null || _p === void 0 ? void 0 : _p.file})` } },
                                    React.createElement("a", { href: `${(_q = realisations[3]) === null || _q === void 0 ? void 0 : _q.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_r = realisations[3]) === null || _r === void 0 ? void 0 : _r.review, defaultMessage: (_s = realisations[3]) === null || _s === void 0 ? void 0 : _s.review })),
                                            React.createElement(Stars, { realisations: realisations[3] })))),
                            React.createElement("article", { className: "is-child small is-6 is-community" },
                                React.createElement("a", { href: "https://www.facebook.com/groups/kust.community", target: "_blank" }, "\u00A0"))))))),
        React.createElement("div", { className: "mansory tile is-ancestor container" },
            React.createElement("div", { className: "tile is-ancestor container" },
                React.createElement("div", { className: "tile is-vertical is-6" },
                    React.createElement("div", { className: "tile" },
                        React.createElement("div", { className: "is-parent is-vertical" },
                            realisations.length > 4 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_t = realisations[4]) === null || _t === void 0 ? void 0 : _t.file})` } },
                                    React.createElement("a", { href: `${(_u = realisations[4]) === null || _u === void 0 ? void 0 : _u.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_v = realisations[4]) === null || _v === void 0 ? void 0 : _v.review, defaultMessage: (_w = realisations[4]) === null || _w === void 0 ? void 0 : _w.review })),
                                            React.createElement(Stars, { realisations: realisations[4] })))),
                            realisations.length > 5 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_x = realisations[5]) === null || _x === void 0 ? void 0 : _x.file})` } },
                                    React.createElement("a", { href: `${(_y = realisations[5]) === null || _y === void 0 ? void 0 : _y.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_z = realisations[5]) === null || _z === void 0 ? void 0 : _z.review, defaultMessage: (_0 = realisations[5]) === null || _0 === void 0 ? void 0 : _0.review })),
                                            React.createElement(Stars, { realisations: realisations[5] }))))),
                        React.createElement("div", { className: "is-parent is-vertical" },
                            realisations.length > 6 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_1 = realisations[6]) === null || _1 === void 0 ? void 0 : _1.file})` } },
                                    React.createElement("a", { href: `${(_2 = realisations[6]) === null || _2 === void 0 ? void 0 : _2.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_3 = realisations[6]) === null || _3 === void 0 ? void 0 : _3.review, defaultMessage: (_4 = realisations[6]) === null || _4 === void 0 ? void 0 : _4.review })),
                                            React.createElement(Stars, { realisations: realisations[6] })))),
                            realisations.length > 7 &&
                                React.createElement("article", { className: "is-child small is-6", style: { 'backgroundImage': `url(${(_5 = realisations[7]) === null || _5 === void 0 ? void 0 : _5.file})` } },
                                    React.createElement("a", { href: `${(_6 = realisations[7]) === null || _6 === void 0 ? void 0 : _6.link}`, target: "_blank" },
                                        React.createElement("div", { className: "contenu" },
                                            React.createElement("p", null,
                                                React.createElement(FormattedMessage, { id: (_7 = realisations[7]) === null || _7 === void 0 ? void 0 : _7.review, defaultMessage: (_8 = realisations[7]) === null || _8 === void 0 ? void 0 : _8.review })),
                                            React.createElement(Stars, { realisations: realisations[7] })))))))),
            React.createElement("div", { className: "tile is-parent" }, realisations.length > 8 &&
                React.createElement("article", { className: "is-child is-12", style: { 'backgroundImage': `url(${(_9 = realisations[8]) === null || _9 === void 0 ? void 0 : _9.file})` } },
                    React.createElement("a", { href: `${(_10 = realisations[8]) === null || _10 === void 0 ? void 0 : _10.link}`, target: "_blank" },
                        React.createElement("div", { className: "contenu" },
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: (_11 = realisations[8]) === null || _11 === void 0 ? void 0 : _11.review, defaultMessage: (_12 = realisations[8]) === null || _12 === void 0 ? void 0 : _12.review })),
                            React.createElement(Stars, { realisations: realisations[8] }))))))));
}
export default Home;
