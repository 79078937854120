import { selectIsAuthenticated } from "common";
import { Button } from "components/Form";
import Img from "components/Img/Img";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { addToCart } from "store/cart/cartActions";
import { fetchColorChartAttempt } from "store/products/productActions";
import { selectColorChart } from "store/products/productSelectors";
import { selectProductAddedToCart } from "store/cart/cartSelectors";
import "./ColorChart.scss";
import { Helmet } from "react-helmet";
import { selectColorCharts } from "store/colorCharts/colorChartsSelectors";
import { fetchColorChartsAttempt } from "store/colorCharts/colorChartsActions";
import { FormattedMessage, useIntl } from "react-intl";
function ColorChart() {
    var _a;
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const [colorChartsSorted, setColorChartsSorted] = useState({});
    const colorChart = useSelector(selectColorChart);
    const colorChartConfig = useSelector(selectColorCharts);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const sortChoices = () => {
        var _a;
        if (!(colorChartConfig === null || colorChartConfig === void 0 ? void 0 : colorChartConfig.colorCharts))
            return;
        const defaultAcc = Object.keys(colorChartConfig === null || colorChartConfig === void 0 ? void 0 : colorChartConfig.colorCharts).reduce((acc, key) => {
            acc[key] = Object.assign(Object.assign({}, colorChartConfig === null || colorChartConfig === void 0 ? void 0 : colorChartConfig.colorCharts[key]), { choices: [] });
            return acc;
        }, {});
        return (_a = colorChart === null || colorChart === void 0 ? void 0 : colorChart.choices) === null || _a === void 0 ? void 0 : _a.reduce((acc, choice) => {
            const matchingKey = Object.keys(colorChartConfig === null || colorChartConfig === void 0 ? void 0 : colorChartConfig.colorCharts).find(key => {
                const match = colorChartConfig === null || colorChartConfig === void 0 ? void 0 : colorChartConfig.colorCharts[key].colorChart.find((code) => choice.code === code);
                return !!match;
            });
            if (!!matchingKey) {
                acc[matchingKey].choices.push(choice);
            }
            else {
                acc.unsorted.push(choice);
            }
            return acc;
        }, Object.assign(Object.assign({}, defaultAcc), { unsorted: [] }));
    };
    useEffect(() => {
        dispatch(fetchColorChartAttempt());
        dispatch(fetchColorChartsAttempt());
    }, []);
    useEffect(() => {
        setColorChartsSorted(sortChoices());
    }, [colorChart, colorChartConfig]);
    const addColorChartToCart = () => {
        var _a;
        const product = Object.assign(Object.assign({}, colorChart), { quantity: 1, choices: (_a = colorChart === null || colorChart === void 0 ? void 0 : colorChart.choices) === null || _a === void 0 ? void 0 : _a.map(choice => (Object.assign(Object.assign({}, choice), { quantity: 1 }))) });
        dispatch(addToCart(product));
    };
    return (React.createElement("div", { className: "color-chart is-fullhd container" }, !!colorChart &&
        React.createElement(React.Fragment, null,
            React.createElement(Helmet, null,
                React.createElement("title", null, "Le Nuancier - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
            React.createElement("h1", null,
                React.createElement(FormattedMessage, { id: colorChart.name, defaultMessage: colorChart.name })),
            !!colorChart.imageUrl &&
                React.createElement(Img, { src: colorChart.imageUrl[0], name: colorChart.name }),
            React.createElement(FormattedMessage, { id: colorChart.description, defaultMessage: colorChart.description }),
            (colorChartsSorted === null || colorChartsSorted === void 0 ? void 0 : colorChartsSorted.unsorted) &&
                React.createElement("div", { className: "choices" }, colorChartsSorted === null || colorChartsSorted === void 0 ? void 0 :
                    colorChartsSorted.unsorted.map((color, key) => (React.createElement("div", { className: "choice-item", key: key },
                        React.createElement("div", null,
                            !!color.imageUrl &&
                                React.createElement(Img, { src: `${color.imageUrl}`, width: 100, height: 100, name: `${color.name}` }),
                            React.createElement("div", null,
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: color === null || color === void 0 ? void 0 : color.name, defaultMessage: color === null || color === void 0 ? void 0 : color.name })),
                                React.createElement("small", null,
                                    React.createElement(FormattedMessage, { id: color === null || color === void 0 ? void 0 : color.description, defaultMessage: color === null || color === void 0 ? void 0 : color.description }))))))), (_a = Object.keys(colorChartsSorted)) === null || _a === void 0 ? void 0 :
                    _a.filter(key => !!colorChartsSorted[key].choices).map((key) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        return (React.createElement("div", { key: key, className: "choices-category" },
                            !!((_a = colorChartsSorted[key]) === null || _a === void 0 ? void 0 : _a.link)
                                ?
                                    React.createElement("h2", null,
                                        React.createElement(Link, { to: (_b = colorChartsSorted[key]) === null || _b === void 0 ? void 0 : _b.link },
                                            React.createElement(FormattedMessage, { id: (_c = colorChartsSorted[key]) === null || _c === void 0 ? void 0 : _c.name, defaultMessage: (_d = colorChartsSorted[key]) === null || _d === void 0 ? void 0 : _d.name })))
                                :
                                    React.createElement("h2", null,
                                        React.createElement(FormattedMessage, { id: (_e = colorChartsSorted[key]) === null || _e === void 0 ? void 0 : _e.name, defaultMessage: (_f = colorChartsSorted[key]) === null || _f === void 0 ? void 0 : _f.name })),
                            React.createElement("span", { className: "description" }, (_g = colorChartsSorted[key]) === null || _g === void 0 ? void 0 : _g.description),
                            React.createElement("div", { className: "choices" }, (_h = colorChartsSorted[key]) === null || _h === void 0 ? void 0 : _h.choices.map((choice) => (React.createElement("div", { className: "choice-item", key: choice._id },
                                React.createElement("div", null,
                                    !!choice.imageUrl &&
                                        React.createElement(Img, { src: `${choice.imageUrl}`, width: 100, height: 100, name: `${choice.imageUrl}` }),
                                    React.createElement("div", null,
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: choice === null || choice === void 0 ? void 0 : choice.name, defaultMessage: choice === null || choice === void 0 ? void 0 : choice.name })),
                                        React.createElement("small", null,
                                            React.createElement(FormattedMessage, { id: choice.description, defaultMessage: choice.description }))))))))));
                    })),
            React.createElement(Link, { to: `/nos-produits/le-nuancier` },
                React.createElement(Button, { buttonName: intl.formatMessage({ id: "J'achète le nuancier", defaultMessage: "J'achète le nuancier" }), className: "orange cart" })),
            React.createElement("div", { className: `modal-success ${productAddedToCart ? 'open' : ''}` },
                React.createElement("svg", { className: "checkmark", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 52 52" },
                    React.createElement("circle", { className: "checkmark__circle", cx: "26", cy: "26", r: "25", fill: "none" }),
                    React.createElement("path", { className: "checkmark__check", fill: "none", d: "M14.1 27.2l7.1 7.2 16.7-16.8" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Produit(s) ajout\u00E9(s) \u00E0 votre panier !", defaultMessage: "Produit(s) ajout\u00E9(s) \u00E0 votre panier !" }))))));
}
export default ColorChart;
