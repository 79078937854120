import { ProductType } from "common";
import React, { useEffect, useState } from "react";
import { ProductChoiceItem } from "./ProductChoiceItem";
import { useDispatch, useSelector } from "react-redux";
import { findFilterName, sortChoices } from "shared/helpers";
import { fetchFiltersAttempt } from "store/filters/filtersActions";
import { selectFilters } from "store/filters/filtersSelectors";
import { FormattedMessage } from "react-intl";
const getMaxCount = (product) => {
    var _a;
    let maxCount = product.count || ((_a = product.element) === null || _a === void 0 ? void 0 : _a.count);
    if (!maxCount && product.type === ProductType.CHOICE_GROUP)
        maxCount = 0;
    else if (!maxCount)
        maxCount = 1000;
    return maxCount;
};
export function ProductChoices({ isAuthenticated, form, replace, toggleModal, AuthModal }) {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const increase = (index, choice) => {
        var _a;
        const maxCount = getMaxCount(form.values);
        const currentCount = ((_a = form.values.choices) === null || _a === void 0 ? void 0 : _a.reduce((acc, curr) => {
            acc += curr.quantity || 0;
            return acc;
        }, 0)) || 0;
        if (currentCount < maxCount)
            replace(index, Object.assign(Object.assign({}, choice), { quantity: (choice.quantity || 0) + 1 }));
    };
    const decrease = (index, choice) => {
        if (choice.quantity > 0)
            replace(index, Object.assign(Object.assign({}, choice), { quantity: (choice.quantity || 0) - 1 }));
    };
    const change = (event, index, choice) => {
        var _a;
        const maxCount = getMaxCount(form.values);
        const value = parseInt(event.target.value, 10);
        const currentCount = ((_a = form.values.choices) === null || _a === void 0 ? void 0 : _a.filter((x) => x._id !== choice._id).reduce((acc, curr) => {
            acc += curr.quantity || 0;
            return acc;
        }, 0)) || 0;
        replace(index, Object.assign(Object.assign({}, choice), { quantity: currentCount + value > maxCount ? maxCount - currentCount : value }));
    };
    const [colorChartsSorted, setColorChartsSorted] = useState({});
    const [choicesSorted, setChoicesSorted] = useState();
    const filters = useSelector(selectFilters);
    useEffect(() => {
        setColorChartsSorted(sortChoices(form.values, "filter"));
    }, [form.values, filters]);
    useEffect(() => {
        dispatch(fetchFiltersAttempt());
    }, []);
    return (React.createElement(React.Fragment, null,
        colorChartsSorted &&
            React.createElement("div", { className: "choices-categories" },
                React.createElement("div", { onClick: () => setChoicesSorted(undefined) },
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "Tous", defaultMessage: "Tous" }))), (_a = Object.keys(colorChartsSorted)) === null || _a === void 0 ? void 0 :
                _a.filter((key) => !!colorChartsSorted[key][0].filter).map((index) => (React.createElement("div", { key: index, onClick: () => setChoicesSorted(colorChartsSorted[index]), className: "choices-category" },
                    React.createElement("h2", null, findFilterName(filters, colorChartsSorted[index])))))),
        choicesSorted === undefined
            ?
                React.createElement(React.Fragment, null, (_b = form.values.choices) === null || _b === void 0 ? void 0 : _b.map((choice, index) => {
                    return React.createElement(ProductChoiceItem, Object.assign({ key: choice._id, cartProduct: form.values }, {
                        increment: () => !isAuthenticated ? toggleModal(AuthModal) : increase(index, choice),
                        decrement: () => decrease(index, choice),
                        onChange: (event) => change(event, index, choice)
                    }, { choice: choice }));
                }))
            :
                React.createElement(React.Fragment, null, (_d = (_c = form.values) === null || _c === void 0 ? void 0 : _c.choices) === null || _d === void 0 ? void 0 : _d.map((choice, index) => {
                    return (choicesSorted.findIndex((x) => x.code === choice.code) !== -1 &&
                        React.createElement(ProductChoiceItem, Object.assign({ key: choice._id, cartProduct: form.values }, {
                            increment: () => !isAuthenticated ? toggleModal(AuthModal) : increase(index, choice),
                            decrement: () => decrease(index, choice),
                            onChange: (event) => change(event, index, choice)
                        }, { choice: choice })));
                }))));
}
