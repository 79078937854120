import { logoutAttempt } from 'common';
import { Button } from 'components/Form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideosAttempt } from 'store/account/videos/videoActions';
import { selectVideos } from 'store/account/videos/videoSelector';
import "./Videos.scss";
import { FormattedMessage, useIntl } from "react-intl";
function Videos() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const videos = useSelector(selectVideos);
    useEffect(() => {
        dispatch(fetchVideosAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos vid\u00E9os", defaultMessage: "Vos vid\u00E9os" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }))),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "videos container" }, (videos === null || videos === void 0 ? void 0 : videos.length) > 0 &&
                React.createElement("div", null, videos.map((video) => React.createElement("div", { className: "video-item", key: video === null || video === void 0 ? void 0 : video._id },
                    React.createElement("video", { controls: true },
                        React.createElement("source", { src: video.link, type: "video/mp4" }),
                        React.createElement("source", { src: video.link, type: "video/mov" })),
                    React.createElement("div", { className: "video-infos" },
                        React.createElement("div", null,
                            React.createElement("strong", null, video.name),
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: video.description } })),
                        React.createElement("span", null,
                            video.duration,
                            " ",
                            React.createElement(FormattedMessage, { id: "min", defaultMessage: "min" }))))))),
            (videos === null || videos === void 0 ? void 0 : videos.length) === 0 &&
                React.createElement("div", null,
                    React.createElement(FormattedMessage, { id: "Il n'y a pas de vid\u00E9os disponibles pour le moment", defaultMessage: "Il n'y a pas de vid\u00E9os disponibles pour le moment" }),
                    "."))));
}
export default Videos;
