import React, { useEffect } from "react";
import { PudosList } from "./components/PudosList";
import { ShippingMode } from "./components/ShippingMode";
import { ShippingDaysOff } from "./components/ShippingDaysOff";
import { fetchPudosAttempt } from "store/shippings/shippingsActions";
import { useDispatch, useSelector } from "react-redux";
import { selectCart } from "store/cart/cartSelectors";
import { selectPudos, selectShippingsFees } from "store/shippings/shippingsSelectors";
import { ShippingModes } from "common";
import { useFormikContext } from "formik";
import Textarea from "components/Form/Inputs/Textarea";
import { setFeesToCart } from "store/cart/cartActions";
import { useIntl } from "react-intl";
export function ShippingsForm({ shippingAddress }) {
    const { values, setFieldValue, resetForm } = useFormikContext();
    const intl = useIntl();
    const dispatch = useDispatch();
    const shippingsFees = useSelector(selectShippingsFees);
    const cart = useSelector(selectCart);
    const pudos = useSelector(selectPudos);
    useEffect(() => {
        resetForm();
    }, [shippingAddress]);
    useEffect(() => {
        if (values.mode) {
            dispatch(setFeesToCart(shippingsFees[values.mode]));
        }
        return () => {
            dispatch(setFeesToCart(null));
        };
    }, [values.mode]);
    useEffect(() => {
        if (values.mode === ShippingModes.PICKUP || values.mode === ShippingModes.B2CPICKUP) {
            dispatch(fetchPudosAttempt({
                addressId: shippingAddress === null || shippingAddress === void 0 ? void 0 : shippingAddress._id,
                products: cart.products,
            }));
        }
        else if (values.mode)
            setFieldValue('pudo', null);
    }, [dispatch, values.mode, values.pudo]);
    return (React.createElement("div", null,
        React.createElement(ShippingMode, { fees: shippingsFees }),
        values.mode === ShippingModes.CLASSIC && (React.createElement("div", null,
            React.createElement(ShippingDaysOff, null),
            React.createElement(Textarea, { name: "note", rows: 10, placeholder: intl.formatMessage({ id: "Entrez votre message ici", defaultMessage: "Entrez votre message ici..." }), label: intl.formatMessage({ id: "3 Un commentaire pour le préparateur de commande", defaultMessage: "3. Un commentaire pour le préparateur de commande" }) }))),
        (values.mode === ShippingModes.PICKUP || values.mode === ShippingModes.B2CPICKUP) && React.createElement(PudosList, { pudos: pudos })));
}
