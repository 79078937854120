import { ProductType, selectIsAuthenticated, BusinessType, } from "common";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { fetchProductAttempt } from "store/products/productActions";
import { selectProduct } from "store/products/productSelectors";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { addToCart, editToCart, selectProductToModify } from "store/cart/cartActions";
import Img from "components/Img/Img";
import "./ProductDetail.scss";
import useModal from "shared/hooks/useModal";
import { truncate } from "shared/helpers";
import { ReviewStars } from "components/Review/ReviewStars";
import { ReviewWidget } from "components/Review/ReviewWidget";
import { selectCartProducts, selectProductAddedToCart, selectProductIndexToModify } from "store/cart/cartSelectors";
import { CheckMarkAnimation } from "components/Animations/CheckMarkAnimation";
import { ProductForm } from "../Form/ProductForm";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import Carousel from "../components/Carousel";
import CarouselSlide from "../components/CarouselSlide";
import { FormattedMessage, useIntl } from "react-intl";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
function ProductDetail() {
    const [MODAL_ID] = useState('freeProductsModal');
    const [reviewId, setReviewId] = useState('');
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { toggleModal, closeModal } = useModal();
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const productIndexToModify = useSelector(selectProductIndexToModify);
    const products = useSelector(selectCartProducts);
    const selectedProductToModify = products[productIndexToModify];
    const product = useSelector(selectProduct(slug));
    const user = useSelector(selectProfileInfos);
    useEffect(() => {
        dispatch(fetchProductAttempt(slug));
        return () => {
            dispatch(selectProductToModify(null));
        };
    }, [dispatch, slug, isAuthenticated]);
    useEffect(() => {
        setReviewId(product === null || product === void 0 ? void 0 : product.reviewId);
    }, [product]);
    const onSubmit = (cartProduct) => {
        !!selectedProductToModify
            ? dispatch(editToCart(Object.assign(Object.assign({}, cartProduct), { quantity: +cartProduct.quantity })))
            : dispatch(addToCart(Object.assign(Object.assign({}, cartProduct), { quantity: +cartProduct.quantity })));
        closeModal(MODAL_ID);
    };
    const intl = useIntl();
    return (React.createElement("div", { className: "container product-detail" }, !!product && (React.createElement("div", { className: "columns is-centered is-multiline is-vcentered" },
        React.createElement("div", { className: "column is-one-third image-column" }, product.imageUrl && product.imageUrl.length === 1 ?
            React.createElement("div", { className: "product-item-image" },
                React.createElement("div", { className: "product-item-image-container" },
                    React.createElement(Img, { src: product.imageUrl[0], name: product.name, alt: product.name })))
            :
                React.createElement(Carousel, null, product === null || product === void 0 ? void 0 : product.imageUrl.map((img) => (React.createElement(CarouselSlide, { key: img, imageUrl: img }))))),
        React.createElement("div", { className: "column is-half product-content" },
            React.createElement(Link, { to: "/nos-produits", className: "go-back" },
                React.createElement("img", { src: "/assets/step-arrow.svg" }),
                " ",
                React.createElement(FormattedMessage, { id: "Retour", defaultMessage: "Retour" })),
            React.createElement("div", null,
                React.createElement(Helmet, null,
                    React.createElement("title", null, product.name),
                    React.createElement("meta", { name: "description", content: truncate(`${product === null || product === void 0 ? void 0 : product.description}`, 150) }),
                    React.createElement("script", { type: "application/ld+json" }, `{
                  "@context": "https://schema.org/",
                  "@type": "Product",
                  "name": "${product.name}",
                  "image": ["${product.imageUrl[0]}"],
                  "description": "${product.description}",
                  "sku": "${product.code}",
                  "brand": {
                    "@type": "Brand",
                    "name": "KUST"
                  }
                }`)),
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name })),
                React.createElement(ReviewStars, { reviewId: reviewId }),
                product.packGuide || product.userGuide || product.ingredients ?
                    React.createElement(Tabs, null,
                        React.createElement(Tab, { title: "Fiche produit", className: "" },
                            React.createElement("div", { className: "other-content", dangerouslySetInnerHTML: { __html: product.description } })),
                        React.createElement(Tab, { title: "Conseil d'utilisation", className: " required-connect" },
                            React.createElement("div", { className: "other-content", dangerouslySetInnerHTML: { __html: product.userGuide } })),
                        React.createElement(Tab, { title: "Ingr\u00E9dients", className: "" },
                            React.createElement("div", { className: "other-content", dangerouslySetInnerHTML: { __html: product.ingredients } })),
                        (!!user && user.businessType === BusinessType.B2B) && React.createElement(Tab, { title: "Tarification", className: " required-connect" },
                            React.createElement("div", { className: "other-content", dangerouslySetInnerHTML: { __html: product.packGuide } })))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement("br", null),
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: product.description } })),
                !!product.informations &&
                    React.createElement("div", { className: "more-infos" },
                        React.createElement("div", { className: "infos" },
                            React.createElement("p", null, truncate(product.informations, 50)),
                            !!product.informationsLink && React.createElement(Link, { to: `${product === null || product === void 0 ? void 0 : product.informationsLink}` },
                                "+ ",
                                React.createElement(FormattedMessage, { id: "Plus d'informations", defaultMessage: "Plus d'informations" })))),
                !!product.button &&
                    React.createElement("div", { className: "more-infos more-infos-alt" },
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: product === null || product === void 0 ? void 0 : product.buttonText } }),
                        !!product.buttonLink && React.createElement(Link, { to: `${product === null || product === void 0 ? void 0 : product.buttonLink}` },
                            React.createElement(FormattedMessage, { id: product.button, defaultMessage: product.button }))))),
        React.createElement("div", { className: "column is-full" },
            React.createElement("div", null,
                React.createElement(Formik, { validateOnChange: true, initialValues: Object.assign(Object.assign({}, product), { quantity: product.type === ProductType.SIMPLE_PRODUCT ? 0 : 1, element: !!selectedProductToModify ? selectedProductToModify === null || selectedProductToModify === void 0 ? void 0 : selectedProductToModify.element : null, choices: !!selectedProductToModify ? selectedProductToModify === null || selectedProductToModify === void 0 ? void 0 : selectedProductToModify.choices : product === null || product === void 0 ? void 0 : product.choices }), enableReinitialize: true, onSubmit: (values, actions) => {
                        onSubmit(values);
                        actions.resetForm();
                    } },
                    React.createElement(ProductForm, { product: product, setReviewId: setReviewId, modalId: MODAL_ID, toggleModal: toggleModal })),
                React.createElement(ReviewWidget, { reviewId: reviewId, product: product }))),
        React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "Produit(s) ajouté(s) à votre panier !", defaultMessage: "Produit(s) ajouté(s) à votre panier !" }), isActive: productAddedToCart })))));
}
export default ProductDetail;
