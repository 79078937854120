import React, { useState } from "react";
import "./Header.scss";
import { Link, useLocation, } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCartProducts, selectProductAddedToCart } from "store/cart/cartSelectors";
import { selectIsAuthenticated } from "common";
import Banner from "containers/Banner/Banner";
import { selectBanner } from "store/banner/bannerSelectors";
import { FormattedMessage } from "react-intl";
function Header() {
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const cartProducts = useSelector(selectCartProducts);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const [menuOpen, setMenuOpen] = useState(false);
    const [kustCommunityOpen, setKustCommunityOpen] = useState(false);
    const [nomadAcademyOpen, setNomadAcademyOpen] = useState(false);
    const { pathname } = useLocation();
    const banner = useSelector(selectBanner);
    const getHeaderClasses = () => {
        const open = menuOpen ? 'open' : '';
        return (pathname === '/' || kustCommunityOpen || nomadAcademyOpen) ? `black-background ${open}` : open;
    };
    const getKustCommunityClass = () => {
        return 'header-page ' + (kustCommunityOpen ? 'header-page-open' : '');
    };
    const getNomadAcademyClass = () => {
        return 'header-page ' + (nomadAcademyOpen ? 'header-page-open' : '');
    };
    const location = useLocation();
    React.useEffect(() => {
        setKustCommunityOpen(false);
        setNomadAcademyOpen(false);
    }, [location]);
    return (React.createElement("div", { className: `${banner ? "banner-active" : ""}` },
        React.createElement("header", { className: getHeaderClasses() },
            React.createElement(Banner, null),
            React.createElement("nav", { className: "navbar ss", role: "navigation", "aria-label": "main navigation" },
                React.createElement("a", { onClick: () => setMenuOpen(!menuOpen), role: "button", className: "navbar-burger burger", "aria-label": "menu", "aria-expanded": "false", "data-target": "navigation" }, "\u00A0"),
                React.createElement("div", { className: "navbar-brand" },
                    React.createElement(Link, { to: "/", className: "navbar-item logo" }, "\u00A0")),
                React.createElement("div", { id: "navigation", className: "navbar-menu" },
                    React.createElement(Link, { to: "/nos-produits", className: "navbar-item" },
                        React.createElement(FormattedMessage, { id: "Nos produits", defaultMessage: "Nos produits" })),
                    React.createElement(Link, { to: "/le-nuancier", className: "navbar-item" },
                        React.createElement(FormattedMessage, { id: "Le nuancier", defaultMessage: "Le nuancier" })),
                    React.createElement("a", { className: "navbar-item", onClick: () => {
                            setKustCommunityOpen(!kustCommunityOpen);
                            setNomadAcademyOpen(false);
                        } },
                        React.createElement(FormattedMessage, { id: "Kust Community", defaultMessage: "Kust Community" })),
                    React.createElement("a", { className: "navbar-item", onClick: () => {
                            setKustCommunityOpen(false);
                            setNomadAcademyOpen(!nomadAcademyOpen);
                        } },
                        React.createElement(FormattedMessage, { id: "Formation", defaultMessage: "Formation" })),
                    React.createElement(Link, { to: "/avis", className: "navbar-item" },
                        React.createElement(FormattedMessage, { id: "Avis Clients", defaultMessage: "Avis Clients" }))),
                React.createElement("div", { className: "navbar-divider" }),
                React.createElement("div", { onClick: () => setMenuOpen(false), className: "navbar-ending" },
                    React.createElement("div", { className: `has-text-centered ${isAuthenticated ? 'account-icon-authenticated' : ''}` },
                        React.createElement(Link, { to: isAuthenticated ? '/mon-compte/profile' : '/authentification', className: "navbar-item account-icon" }, "\u00A0")),
                    React.createElement(Link, { to: "/panier", className: `navbar-item cart-icon ${productAddedToCart ? 'add-to-cart-animation' : ''}`, suppressHydrationWarning: true },
                        "\u00A0",
                        cartProducts.length > 0 &&
                            React.createElement("div", { className: "has-text-centered cart-color" },
                                React.createElement("div", null, cartProducts.reduce((quantity, product) => { return quantity + product.quantity; }, 0)))))),
            React.createElement("div", { onClick: () => setMenuOpen(false), className: "nav-mobile" },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/" },
                            React.createElement(FormattedMessage, { id: "Accueil", defaultMessage: "Accueil" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/nos-produits" },
                            React.createElement(FormattedMessage, { id: "Nos produits", defaultMessage: "Nos produits" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/le-nuancier" },
                            React.createElement(FormattedMessage, { id: "Le nuancier", defaultMessage: "Le nuancier" }))),
                    React.createElement("li", null,
                        React.createElement("a", { onClick: () => {
                                setKustCommunityOpen(!kustCommunityOpen);
                                setNomadAcademyOpen(false);
                            } },
                            React.createElement(FormattedMessage, { id: "Kust Community", defaultMessage: "Kust Community" }))),
                    React.createElement("li", null,
                        " ",
                        React.createElement(Link, { to: "/avis" },
                            React.createElement(FormattedMessage, { id: "Avis Clients", defaultMessage: "Avis Clients" })))),
                React.createElement("div", { className: "divider" }),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/mon-compte/profile" }, isAuthenticated ? 'Mon compte' : 'Se connecter / M’inscrire'))),
                React.createElement("div", { className: "divider" }),
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/coloration-essential" },
                            React.createElement(FormattedMessage, { id: "La coloration", defaultMessage: "La coloration" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/a-propos" },
                            React.createElement(FormattedMessage, { id: "Kust, c'est quoi ?", defaultMessage: "Kust, c'est quoi ?" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/confidentialite" },
                            React.createElement(FormattedMessage, { id: "Confidentialit\u00E9", defaultMessage: "Confidentialit\u00E9" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/cgv" },
                            React.createElement(FormattedMessage, { id: "CGV", defaultMessage: "CGV" }))),
                    React.createElement("li", null,
                        React.createElement(Link, { to: "/pages/nous-contacter" },
                            React.createElement(FormattedMessage, { id: "Nous contacter", defaultMessage: "Nous contacter" }))))),
            React.createElement("div", { className: getKustCommunityClass() },
                React.createElement("div", { className: "header-page-content" },
                    React.createElement("img", { src: "/assets/kust_community-communaute-clients-kust.png" }),
                    React.createElement("div", { className: "header-page-texts" },
                        React.createElement("div", { className: "" },
                            React.createElement("small", null,
                                React.createElement(FormattedMessage, { id: "Rejoignez sur Facebook", defaultMessage: "Rejoignez sur Facebook" })),
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "La Kust Community", defaultMessage: "La Kust Community" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "\u00AB Seul on va plus vite, ensemble on va plus loin \u00BB", defaultMessage: "\u00AB\u00A0Seul on va plus vite, ensemble on va plus loin\u00A0\u00BB" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Depuis sa cr\u00E9ation, nous avons \u00E0 c\u0153ur de partager avec nos clients la passion de la coiffure", defaultMessage: "Depuis sa cr\u00E9ation, nous avons \u00E0 c\u0153ur de partager avec nos clients la passion de la coiffure" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Parce que nous croyons \u00E0 la bienveillance et \u00E0 l\u2019\u00E9change, nous avons cr\u00E9\u00E9 une communaut\u00E9 unique, o\u00F9 Kusteurs partagent recettes, r\u00E9alisations mais \u00E9galement astuces", defaultMessage: "Parce que nous croyons \u00E0 la bienveillance et \u00E0 l\u2019\u00E9change, nous avons cr\u00E9\u00E9 une communaut\u00E9 unique, o\u00F9 Kusteurs partagent recettes, r\u00E9alisations mais \u00E9galement astuces" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Une parole d\u00E9complex\u00E9e et d\u00E9complexante, o\u00F9 chacun exprime son art et sa vision de la profession", defaultMessage: 'Une parole d\u00E9complex\u00E9e et d\u00E9complexante, o\u00F9 chacun exprime son art et sa vision de la profession' }),
                                "."),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Une connexion entre vous et nous\u00A0! Et un lien permanent d\u2019une richesse incomparable", defaultMessage: "Une connexion entre vous et nous\u00A0! Et un lien permanent d\u2019une richesse incomparable" }),
                                " ",
                                React.createElement("br", null))),
                        React.createElement("a", { href: "https://www.facebook.com/groups/kust.community/", target: "_blank" },
                            React.createElement("button", { className: "orange" },
                                React.createElement(FormattedMessage, { id: "Rejoindre maintenant", defaultMessage: "Rejoindre maintenant" })))))),
            React.createElement("div", { className: getNomadAcademyClass() },
                React.createElement("div", { className: "header-page-content" },
                    React.createElement("img", { src: "/assets/formation.jpeg" }),
                    React.createElement("div", { className: "header-page-texts" },
                        React.createElement("div", { className: "" },
                            React.createElement("strong", null,
                                React.createElement(FormattedMessage, { id: "Nomad Academy", defaultMessage: "Nomad Academy" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "\u00AB\u00A0Cesser d'apprendre c'est commencer \u00E0 r\u00E9gresser\u00A0\u00BB", defaultMessage: "\u00AB\u00A0Cesser d'apprendre c'est commencer \u00E0 r\u00E9gresser\u00A0\u00BB" }),
                                "."),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Car la passion est aussi une question de transmission, KUST a cr\u00E9\u00E9 son acad\u00E9mie de formation pour permettre \u00E0 tous/tes de devenir des experts de la couleur", defaultMessage: "Car la passion est aussi une question de transmission, KUST a cr\u00E9\u00E9 son acad\u00E9mie de formation pour permettre \u00E0 tous/tes de devenir des experts de la couleur" })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Des formations pens\u00E9es pour vous et avec vous", defaultMessage: "Des formations pens\u00E9es pour vous et avec vous." })),
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { id: "Aupr\u00E8s de formatrices de choc, prenez le contr\u00F4le !", defaultMessage: "Aupr\u00E8s de formatrices de choc, prenez le contr\u00F4le !" }))),
                        React.createElement("a", { href: "https://nomadacademy.fr", target: "_blank" },
                            React.createElement("button", { className: "orange" },
                                React.createElement(FormattedMessage, { id: "En savoir plus", defaultMessage: "En savoir plus" })))))))));
}
export default Header;
