import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectGenericErrors, selectIsAuthenticated } from "common";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { fetchProductsAttempt } from "store/products/productActions";
import { selectProductsFiltered } from "store/products/productSelectors";
import ProductListItem from "../components/ProductListItem";
import { ProductsFilters } from "../Filter/ProductsFilters";
import "./ProductsList.scss";
import { selectTags } from "store/tags/tagSelectors";
function ProductsList() {
    function slugify(text) {
        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        const to = "aaaaaeeeeeiiiiooooouuuunc------";
        const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));
        return newText
            .toString()
            .toLowerCase()
            .trim()
            .replace('#', '-')
            .replace(/\s+/g, '-')
            .replace(/&/g, '-y-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-');
    }
    const dispatch = useDispatch();
    const genericError = useSelector(selectGenericErrors('FETCH_PRODUCTS'));
    const products = useSelector(selectProductsFiltered);
    const isAuthenticated = useSelector(selectIsAuthenticated());
    const user = useSelector(selectProfileInfos);
    const { tag } = useParams();
    const tags = useSelector(selectTags);
    const targetTag = tag && tags.find((t) => slugify(t.name) === tag);
    useEffect(() => {
        dispatch(fetchProductsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "container products-list" },
        React.createElement("div", { className: "columns" },
            React.createElement("div", { className: "column is-3 is-offset-9" },
                React.createElement(ProductsFilters, null))),
        React.createElement(Helmet, null,
            React.createElement("title", null, "Nos produits - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
        (products === null || products === void 0 ? void 0 : products.length) > 0 &&
            React.createElement("div", { className: "columns is-multiline" }, products.map(product => {
                const associatedTag = tags.find(tag => tag._id === product.tag);
                if (!associatedTag)
                    return null;
                if (!tag && associatedTag.isDisplayable) {
                    return (React.createElement("div", { className: "column is-one-third", key: product === null || product === void 0 ? void 0 : product._id },
                        React.createElement(ProductListItem, { path: `/nos-produits/${product.slug}`, product: product })));
                }
                if (tag && targetTag && product.tag === targetTag._id) {
                    return (React.createElement("div", { className: "column is-one-third", key: product === null || product === void 0 ? void 0 : product._id },
                        React.createElement(ProductListItem, { path: `/nos-produits/${product.slug}`, product: product })));
                }
                return null;
            })),
        genericError && React.createElement("h1", null, genericError)));
}
export default ProductsList;
