import { getChoicesQuantity, logoutAttempt, ProductType, convertToEuros, BusinessType } from 'common';
import { Angle } from 'components/Angle/Angle';
import { CheckMarkAnimation } from 'components/Animations/CheckMarkAnimation';
import { Button } from 'components/Form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStep from 'shared/hooks/useStep';
import { fetchOrdersAttempt } from 'store/account/orders/ordersActions';
import { selectOrders } from 'store/account/orders/ordersSelectors';
import { addToCart } from 'store/cart/cartActions';
import { selectProductAddedToCart } from 'store/cart/cartSelectors';
import "./Commandes.scss";
import { FormattedMessage, useIntl } from "react-intl";
function Commandes() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useStep();
    const orders = useSelector(selectOrders);
    const productAddedToCart = useSelector(selectProductAddedToCart);
    const loadCart = (products) => {
        for (const product of products) {
            if (!product.expired) {
                dispatch(addToCart(product));
            }
        }
    };
    const isReusableOrder = (products) => {
        return products.filter(product => product.expired).length === products.length;
    };
    useEffect(() => {
        dispatch(fetchOrdersAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos commandes", defaultMessage: "Vos commandes" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }),
                    "Bienvenue dans votre espace personnel KUST !")),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "commandes" },
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "Historique de mes commandes et factures", defaultMessage: "Historique de mes commandes et factures" }),
                    "."),
                React.createElement("div", { className: "orders-list" }, orders.length > 0 && orders.map((order) => {
                    var _a;
                    return React.createElement("div", { key: order.orderId, className: `order-item ${activeStep === order.orderId ? 'open' : ''}` },
                        React.createElement("div", { onClick: () => setActiveStep(order.orderId), className: "order-toggle" },
                            order.createdAt,
                            " - ",
                            order.orderId,
                            " :"),
                        React.createElement("div", { className: "order-divider" },
                            React.createElement(Angle, { isActive: activeStep === order.orderId, setActiveStep: setActiveStep, step: order.orderId })),
                        React.createElement("div", { className: "order-content" },
                            order.products.length
                                ? React.createElement("div", { className: "column is-mobile" },
                                    React.createElement("div", { className: "order-list" },
                                        order.products.map((product, index) => {
                                            return (React.createElement("div", { className: "order-list-item", key: index },
                                                React.createElement("span", { className: product.expired ? 'expired' : '' },
                                                    React.createElement(FormattedMessage, { id: product.name, defaultMessage: product.name }),
                                                    " x ",
                                                    product.quantity),
                                                React.createElement("span", null,
                                                    (convertToEuros(order.businessType === BusinessType.B2B ? product.price : product.b2cPrice
                                                        * (product.type === ProductType.CHOICE_PRODUCT && !product.restricted || product.type === ProductType.SCALE_GROUP ? getChoicesQuantity(product) : 1)
                                                        * product.quantity)),
                                                    "\u20AC")));
                                        }),
                                        React.createElement("div", { className: "order-list-item" },
                                            React.createElement("span", null,
                                                React.createElement("strong", null,
                                                    React.createElement(FormattedMessage, { id: "Total", defaultMessage: "Total" }))),
                                            React.createElement("span", null,
                                                React.createElement("strong", null,
                                                    convertToEuros(order.total),
                                                    "\u20AC ",
                                                    React.createElement(FormattedMessage, { id: "HT", defaultMessage: "HT" }))))))
                                : React.createElement("div", null,
                                    React.createElement(FormattedMessage, { id: "Aucune commande pass\u00E9e pour le moment", defaultMessage: "Aucune commande pass\u00E9e pour le moment" })),
                            React.createElement("div", { className: "order-content-item columns bill" },
                                React.createElement("div", { className: "column is-full" },
                                    ((_a = order === null || order === void 0 ? void 0 : order.billing) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined
                                        ?
                                            React.createElement("a", { href: order.billing.viewUrl, target: "_blank" },
                                                React.createElement(Button, { className: "black-white", buttonName: intl.formatMessage({ id: "Télécharger ma facture", defaultMessage: "Télécharger ma facture" }) }))
                                        : '',
                                    React.createElement(Button, { className: "orange reuse-cart-button", buttonName: intl.formatMessage({ id: "Replacer dans le panier", defaultMessage: "Replacer dans le panier" }), disabled: isReusableOrder(order.products), onClick: () => { loadCart(order.products); } })))));
                })))),
        React.createElement(CheckMarkAnimation, { message: intl.formatMessage({ id: "Produit(s) ajouté(s) à votre panier !", defaultMessage: "Produit(s) ajouté(s) à votre panier !" }), isActive: productAddedToCart })));
}
export default Commandes;
