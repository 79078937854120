import { logoutAttempt } from 'common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileAttempt } from 'store/account/profile/profileActions';
import { selectTrainings } from 'store/account/profile/profileSelectors';
import Button from "components/Form/Buttons/Button";
import "./Formations.scss";
import { FormattedMessage, useIntl } from "react-intl";
function Formations() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const trainings = useSelector(selectTrainings);
    useEffect(() => {
        dispatch(fetchProfileAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos formations", defaultMessage: "Vos formations" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }))),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "trainings container" },
                (trainings === null || trainings === void 0 ? void 0 : trainings.length) > 0 &&
                    React.createElement("div", null, trainings.reverse().map((training) => React.createElement("div", { className: "training-item", key: training === null || training === void 0 ? void 0 : training._id },
                        React.createElement("div", null,
                            React.createElement("strong", null, training.name),
                            React.createElement("p", null,
                                training.address,
                                " ",
                                training.postalCode,
                                " ",
                                training.city),
                            React.createElement("p", null,
                                training.date,
                                ": ",
                                training.schedule),
                            training.secondDate && React.createElement("p", null,
                                training.secondDate,
                                ": ",
                                training.secondSchedule))))),
                (trainings === null || trainings === void 0 ? void 0 : trainings.length) === 0 &&
                    React.createElement("div", null,
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { id: "Aucune formations pour le moment", defaultMessage: "Aucune formations pour le moment" }),
                            "..."))))));
}
export default Formations;
