import { Summary } from "components/Cart/Summary";
import React, { createContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectCart } from "store/cart/cartSelectors";
import { OrderSteps } from "./OrderSteps";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";
export const OrderContext = createContext({});
function Order() {
    const ref = useRef(null);
    const intl = useIntl();
    const [order, setOrder] = useState({
        billingAddress: null,
        shippingAddress: null,
        shippingInfos: null,
        paymentMode: null
    });
    const setOrderValue = (name, value) => {
        setOrder((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const cart = useSelector(selectCart);
    return (React.createElement(OrderContext.Provider, { value: ref },
        React.createElement("div", { className: "cart container" },
            React.createElement("div", { className: "columns is-multiline is-mobile is-centered order-tunnel" },
                React.createElement(Link, { to: "/panier", className: "go-back is-half" },
                    React.createElement("img", { src: "/assets/step-arrow.svg" }),
                    React.createElement(FormattedMessage, { id: "Retour au panier", defaultMessage: "Retour au panier" })),
                React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                    React.createElement("div", { className: "content" },
                        React.createElement(OrderSteps, { order: order, setOrderValue: setOrderValue }))),
                !isMobile &&
                    React.createElement("div", { ref: ref, className: "column is-one-quarter black-block" },
                        React.createElement(Summary, { title: intl.formatMessage({ id: "Mon récapitulatif", defaultMessage: "Mon récapitulatif" }), cart: cart }))))));
}
export default Order;
