import { BusinessType, Countries, getCountryByCode } from "common";
import { Input, InputError, Select } from "components/Form";
import { Form } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
export function AddressForm({ children, apiErrors }) {
    const intl = useIntl();
    const profile = useSelector(selectProfileInfos);
    return (React.createElement(Form, { noValidate: true, autoComplete: 'off' },
        React.createElement(Input, { name: "name", label: intl.formatMessage({ id: 'Nom de l\'adresse', defaultMessage: 'Nom de l\'adresse' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.name }),
        React.createElement(Input, { name: "lastname", label: intl.formatMessage({ id: 'Nom', defaultMessage: 'Nom' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.lastname }),
        React.createElement(Input, { name: "firstname", label: intl.formatMessage({ id: 'Prénom', defaultMessage: 'Prénom' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.firstname }),
        (profile === null || profile === void 0 ? void 0 : profile.businessType) === BusinessType.B2B &&
            React.createElement(React.Fragment, null,
                React.createElement(Input, { name: "salon", label: intl.formatMessage({ id: 'Nom du salon', defaultMessage: 'Nom du salon' }), className: "grey label-left" }),
                React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.salon })),
        React.createElement(Input, { name: "address", label: intl.formatMessage({ id: 'Adresse', defaultMessage: 'Adresse' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.address }),
        React.createElement(Select, { label: intl.formatMessage({ id: 'Pays', defaultMessage: 'Pays' }), defaultLabel: intl.formatMessage({ id: 'Je choisis mon pays de livraison', defaultMessage: 'Je choisis mon pays de livraison' }), name: "country", displayKey: "name", valueKey: "value", className: "grey label-left", options: Object.values(Countries)
                .map(country => {
                return { name: getCountryByCode(country).toUpperCase(), value: country };
            }) }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.country }),
        React.createElement(Input, { name: "city", label: intl.formatMessage({ id: 'Ville', defaultMessage: 'Ville' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.city }),
        React.createElement(Input, { name: "postalCode", label: intl.formatMessage({ id: 'Code postal', defaultMessage: 'Code postal' }), type: "number", className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.postalCode }),
        React.createElement(Input, { name: "phone", label: intl.formatMessage({ id: 'Tel Mobile', defaultMessage: 'Tel. Mobile' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phone }),
        React.createElement(Input, { name: "phoneFix", label: intl.formatMessage({ id: 'Tel Fixe', defaultMessage: 'Tel. Fixe' }), className: "grey label-left" }),
        React.createElement(InputError, { apiErrors: apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.phoneFix }),
        children));
}
