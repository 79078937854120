import React from "react";
import { Days } from "common";
import { capitalize } from "shared/helpers";
import { FieldArray } from "formik";
import { FormattedMessage } from "react-intl";
export function ShippingDaysOff() {
    return (React.createElement("div", null,
        React.createElement("p", null,
            React.createElement("br", null),
            "2. ",
            React.createElement(FormattedMessage, { id: "Quels sont les jours de fermeture de votre salon ?", defaultMessage: "Quels sont les jours de <b>fermeture</b> de votre salon ?", values: { b: chunks => React.createElement("b", null, chunks) } })),
        React.createElement("div", { className: "days-off-list" },
            React.createElement(FieldArray, { name: "daysOff" }, ({ remove, push, form }) => (Object.values(Days).map((day, index) => {
                return React.createElement("div", { key: index },
                    React.createElement("label", { className: "is-checkbox radio radio-before", htmlFor: day },
                        React.createElement("span", { className: "radio__input" },
                            React.createElement("input", { id: day, onClick: () => {
                                    const dayIndex = form.values.daysOff.indexOf(day);
                                    dayIndex !== -1 ? remove(dayIndex) : push(day);
                                }, name: "daysOff", type: "checkbox", value: day }),
                            React.createElement("span", { className: "radio__control" })),
                        React.createElement("strong", null,
                            React.createElement(FormattedMessage, { id: capitalize(day), defaultMessage: capitalize(day) }))));
            }))))));
}
