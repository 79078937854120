import { BusinessType } from "common";
import { Button } from "components/Form";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrder } from "store/order/orderSelectors";
import { CartInfos } from "components/Cart/CartInfos";
import { Link } from "react-router-dom";
import "./OrderSuccess.scss";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { FormattedMessage, useIntl } from "react-intl";
import { resetCart } from "store/cart/cartActions";
export function OrderSuccess() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const order = useSelector(selectOrder);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const payment_link_id = searchParams.get('payment_link_id');
    const items = order === null || order === void 0 ? void 0 : order.products.map((product) => {
        return {
            id: product._id,
            name: product.name,
            sku: product.code,
            price: (order.businessType === BusinessType.B2B ? product.priceVat / 10 : product.b2cPriceVat / 10),
            quantity: product.quantity,
        };
    });
    ReactGA.plugin.require('ecommerce');
    ReactGA.plugin.execute('ecommerce', 'addItem', items);
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: order === null || order === void 0 ? void 0 : order.orderId,
        revenue: (order === null || order === void 0 ? void 0 : order.totalVatWithPromo) ? order.totalVatWithPromo / 10 : 0,
    });
    ReactGA.plugin.execute('ecommerce', 'send', '');
    ReactGA.plugin.execute('ecommerce', 'clear', '');
    dispatch(resetCart());
    useEffect(() => {
        if (!order && !payment_link_id)
            dispatch(push('/'));
    }, [order]);
    return (React.createElement("div", { className: "cart container order-success" },
        order &&
            React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
                React.createElement(Helmet, null,
                    React.createElement("title", null, "F\u00E9licitation - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
                React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "Merci !", defaultMessage: "Merci !" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "Votre commande a bien \u00E9t\u00E9 enregistr\u00E9e", defaultMessage: "Votre commande a bien \u00E9t\u00E9 enregistr\u00E9e" }),
                        "."),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: 'Nous avons h\u00E2te que votre talent s\u2019exprime avec nos produits\u00A0! Une confirmation de commande vous a \u00E9t\u00E9 envoy\u00E9e \u00E0 l\u2019adresse mail indiqu\u00E9e lors de votre achat', defaultMessage: "Nous avons h\u00E2te que votre talent s\u2019exprime avec nos produits\u00A0!\n                            Une confirmation de commande vous a \u00E9t\u00E9 envoy\u00E9e \u00E0 l\u2019adresse mail indiqu\u00E9e lors de votre achat" }),
                        ".",
                        React.createElement(FormattedMessage, { id: 'Si aucun mail n\u2019appara\u00EEt, pensez \u00E0 v\u00E9rifier dans votre spam', defaultMessage: 'Si aucun mail n\u2019appara\u00EEt, pensez \u00E0 v\u00E9rifier dans votre spam ' }),
                        ".",
                        React.createElement(FormattedMessage, { id: 'Pour suivre l\u2019\u00E9tat de votre commande, rendez-vous sur votre espace \u00AB\u00A0compte client\u00A0\u00BB', defaultMessage: 'Pour suivre l\u2019\u00E9tat de votre commande, rendez-vous sur votre espace \u00AB\u00A0compte client\u00A0\u00BB' }),
                        ".",
                        React.createElement(FormattedMessage, { id: 'En cas d\u2019incident, merci d\u2019adresser votre requ\u00EAte via', defaultMessage: 'En cas d\u2019incident, merci d\u2019adresser votre requ\u00EAte via ' }),
                        React.createElement(Link, { to: "/pages/nous-contacter", className: "contact" },
                            React.createElement(FormattedMessage, { id: "nous contacter", defaultMessage: "nous contacter" }),
                            "."))),
                React.createElement("div", { className: "column is-one-quarter black-block" },
                    React.createElement(CartInfos, { cart: order },
                        React.createElement(Link, { to: "/mon-compte/commandes" },
                            React.createElement(Button, { type: "button", buttonName: intl.formatMessage({ id: "Mes commandes", defaultMessage: "Mes commandes" }), className: "dark" }))))),
        payment_link_id &&
            React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
                React.createElement(Helmet, null,
                    React.createElement("title", null, "Votre paiement est en cours de traitement - KUST | La Marque des Coiffeurs pas Comme Les Autres")),
                React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "Merci !", defaultMessage: "Merci !" })),
                    React.createElement("h2", null,
                        React.createElement(FormattedMessage, { id: "Votre commande a bien \u00E9t\u00E9 enregistr\u00E9e", defaultMessage: "Votre commande a bien \u00E9t\u00E9 enregistr\u00E9e" }),
                        "."),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: 'Nous avons h\u00E2te que votre talent s\u2019exprime avec nos produits\u00A0! Une confirmation de commande vous a \u00E9t\u00E9 envoy\u00E9e \u00E0 l\u2019adresse mail indiqu\u00E9e lors de votre achat', defaultMessage: "Nous avons h\u00E2te que votre talent s\u2019exprime avec nos produits\u00A0!\n                            Une confirmation de commande vous a \u00E9t\u00E9 envoy\u00E9e \u00E0 l\u2019adresse mail indiqu\u00E9e lors de votre achat" }),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: 'Si aucun mail n\u2019appara\u00EEt, pensez \u00E0 v\u00E9rifier dans votre spam', defaultMessage: 'Si aucun mail n\u2019appara\u00EEt, pensez \u00E0 v\u00E9rifier dans votre spam ' }),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: 'Pour suivre l\u2019\u00E9tat de votre commande, rendez-vous sur votre espace \u00AB\u00A0compte client\u00A0\u00BB', defaultMessage: 'Pour suivre l\u2019\u00E9tat de votre commande, rendez-vous sur votre espace \u00AB\u00A0compte client\u00A0\u00BB' }),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement(FormattedMessage, { id: 'En cas d\u2019incident, merci d\u2019adresser votre requ\u00EAte via', defaultMessage: 'En cas d\u2019incident, merci d\u2019adresser votre requ\u00EAte via ' }),
                        React.createElement(Link, { to: "/pages/nous-contacter", className: "contact" },
                            React.createElement(FormattedMessage, { id: "nous contacter", defaultMessage: "nous contacter" }),
                            "."))))));
}
