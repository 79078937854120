import React from "react";
import "./Login.scss";
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, InputError } from "components/Form";
import { Link } from "react-router-dom";
import { loginAttempt, selectErrors } from "common";
import { Formik, Form } from "formik";
import useModal from "shared/hooks/useModal";
import Socials from "containers/Socials/Socials";
import { FormattedMessage, useIntl } from "react-intl";
function Login({ modalId }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { isModalOpen, closeModal } = useModal();
    const errors = useSelector(selectErrors('LOGIN'));
    console.log('errors', errors);
    const onSubmit = (credentials) => {
        dispatch(loginAttempt(credentials));
        closeModal(modalId);
    };
    return (React.createElement("div", { className: "login has-text-centered" },
        React.createElement("h1", null,
            React.createElement(FormattedMessage, { id: "D\u00E9j\u00E0 client ?", defaultMessage: "D\u00E9j\u00E0 client ?" })),
        React.createElement("small", null,
            React.createElement(FormattedMessage, { id: "Connectez-vous ci-dessous :", defaultMessage: "Connectez-vous ci-dessous :" })),
        React.createElement(Socials, null),
        React.createElement(Formik, { validateOnChange: true, initialValues: {
                email: '',
                password: '',
                remember_me: false
            }, enableReinitialize: true, onSubmit: onSubmit },
            React.createElement(Form, null,
                React.createElement(Input, { name: "email", label: intl.formatMessage({ id: 'E-mail', defaultMessage: 'E-mail' }), color: "white" }),
                React.createElement(Input, { name: "password", type: "password", label: intl.formatMessage({ id: 'Mot de passe', defaultMessage: 'Mot de passe' }), color: "white" }),
                React.createElement(Button, { className: "orange", type: "submit", buttonName: intl.formatMessage({ id: "Connexion", defaultMessage: "Connexion" }) }),
                React.createElement(InputError, { apiErrors: errors === null || errors === void 0 ? void 0 : errors.login }),
                React.createElement("small", null,
                    React.createElement(Link, { to: "/recuperation" },
                        React.createElement(FormattedMessage, { id: "Mot de passe oubli\u00E9 ?", defaultMessage: "Mot de passe oubli\u00E9 ?" })))))));
}
/*
    A revoir, ce n'est pas dans le design
                <!--<label>Se souvenir de moi</label>
                <Input type="checkbox" name="remember_me" />-->

*/
export default Login;
