import React from "react";
import { FormattedMessage } from "react-intl";
import { getActiveStep, getProductsSteps } from "services/utils/products.steps";
export function ProductSteps({ product }) {
    return (React.createElement("div", { className: "navigation" }, getProductsSteps(product)
        .map((step, index) => {
        const stepCount = index + 1;
        return (React.createElement("div", { key: index, className: `navigation-item ${getActiveStep(product) >= stepCount && "active" || getActiveStep(product) === stepCount && "current"}` },
            React.createElement("span", null,
                React.createElement("strong", null, stepCount)),
            React.createElement(FormattedMessage, { id: step.message, defaultMessage: step.message }),
            stepCount < getProductsSteps(product).length && (React.createElement("div", { className: "arrow" }))));
    })));
}
