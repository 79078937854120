import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchContentAttempt } from "store/contents/contentsActions";
import { selectContent } from "store/contents/contentsSelectors";
import "./Contents.scss";
import Img from 'components/Img/Img';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
function Content() {
    var _a;
    const { slug } = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    const content = useSelector(selectContent(slug));
    useEffect(() => {
        dispatch(fetchContentAttempt(slug));
    }, [dispatch, slug]);
    return (React.createElement("div", null, !!content && (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, content.title),
            content.metaDescription && React.createElement("meta", { name: "description", content: content.metaDescription }),
            content.metaKeywords && React.createElement("meta", { name: "keywords", content: content.metaKeywords })),
        (content === null || content === void 0 ? void 0 : content.file) &&
            React.createElement("div", { className: "container content-head" },
                React.createElement("div", { className: "content-image-container", style: { 'backgroundImage': `url(${content.file})` } },
                    React.createElement("div", { className: "content-image-window", style: { 'backgroundImage': `url(${content.file})` } },
                        React.createElement("div", { className: "content-image-content" },
                            React.createElement("h1", null,
                                React.createElement(FormattedMessage, { id: content.title, defaultMessage: content.title })),
                            content.subtitle && React.createElement("h2", null,
                                React.createElement(FormattedMessage, { id: content.subtitle, defaultMessage: content.subtitle })))))),
        React.createElement("div", { className: "container content-body" },
            !content.file && React.createElement(React.Fragment, null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: content.title, defaultMessage: content.title })),
                content.subtitle && React.createElement("h2", null,
                    React.createElement(FormattedMessage, { id: content.subtitle, defaultMessage: content.subtitle })),
                React.createElement("br", null)),
            React.createElement("div", null,
                !!content.introTitle &&
                    React.createElement("div", { className: "columns is-vcentered is-variable is-centered content-block" },
                        React.createElement("div", { className: "column is-12-desktop is-12-mobile" },
                            content.introTitle && React.createElement("h3", null,
                                React.createElement(FormattedMessage, { id: content.introTitle, defaultMessage: content.introTitle })),
                            React.createElement("div", { dangerouslySetInnerHTML: { __html: content.introContent } }))), (_a = content.content) === null || _a === void 0 ? void 0 :
                _a.map((contentBlock, i) => React.createElement("div", { className: "columns is-vcentered is-variable is-centered content-block", key: contentBlock === null || contentBlock === void 0 ? void 0 : contentBlock._id },
                    React.createElement("div", { className: `column is-${contentBlock.file ? '8' : '12'}-desktop is-12-mobile` },
                        contentBlock.title && React.createElement("h3", null,
                            React.createElement(FormattedMessage, { id: contentBlock.title, defaultMessage: contentBlock.title })),
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: contentBlock.content } })),
                    contentBlock.file !== undefined
                        ? React.createElement("div", { className: i % 2 !== 0 ? 'image-left content-image column is-4-desktop is-12-mobile' : 'content-image is-4-desktop is-12-mobile' },
                            React.createElement(Img, { src: `${contentBlock === null || contentBlock === void 0 ? void 0 : contentBlock.file}`, name: `${contentBlock.title}` }))
                        : null))),
            !!content.buttonLink && React.createElement(Link, { className: "link", to: content.buttonLink },
                React.createElement("button", { className: "black-white" },
                    React.createElement(FormattedMessage, { id: content.buttonTitle, defaultMessage: content.buttonTitle }))))))));
}
export default Content;
