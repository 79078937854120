import React, { useEffect } from 'react';
import "./Navigation.scss";
import { Link, useParams } from "react-router-dom";
import Profile from '../Profile/Profile';
import Commandes from '../Commandes/Commandes';
import Adresses from '../Adresses/Adresses';
import Videos from '../Videos/Videos';
import Formations from '../Formations/Formations';
import Documents from '../Documents/Documents';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAttempt } from 'common';
import { useDropdown } from 'shared/hooks/useDropdown';
import { selectProfileInfos } from 'store/account/profile/profileSelectors';
import { fetchDocumentsAttempt } from 'store/account/documents/documentActions';
import { selectDocument } from 'store/account/documents/documentSelectors';
import { fetchOrdersAttempt } from 'store/account/orders/ordersActions';
import { selectOrders } from 'store/account/orders/ordersSelectors';
import Devis from '../Devis/Devis';
import Factures from '../Factures/Factures';
import Credits from '../Credits/Credits';
import { selectCredits } from 'store/account/credits/creditsSelectors';
import { fetchCreditsAttempt } from 'store/account/credits/creditsActions';
import { FormattedMessage } from "react-intl";
var Pages;
(function (Pages) {
    Pages["COMMANDS"] = "commandes";
    Pages["BILLS"] = "factures";
    Pages["VIDEOS"] = "videos";
    Pages["TRAININGS"] = "formations";
    Pages["DOCUMENTS"] = "documents";
    Pages["ADDRESSES"] = "adresses";
    Pages["PROFILE"] = "profile";
    Pages["QUOTATIONS"] = "devis";
    Pages["CREDITS"] = "credits";
})(Pages || (Pages = {}));
function Navigation() {
    var _a;
    const { page } = useParams();
    const { dropdownRef, dropdownActive, setDropdownActive } = useDropdown();
    const dispatch = useDispatch();
    const isPageActive = (value) => {
        return value === page ? 'active' : '';
    };
    const profile = useSelector(selectProfileInfos);
    const documents = useSelector(selectDocument);
    const orders = useSelector(selectOrders);
    const credits = useSelector(selectCredits);
    const showCurrentPage = () => {
        switch (page) {
            case Pages.COMMANDS:
                return React.createElement(Commandes, null);
            case Pages.BILLS:
                return React.createElement(Factures, null);
            case Pages.VIDEOS:
                return React.createElement(Videos, null);
            case Pages.TRAININGS:
                return React.createElement(Formations, null);
            case Pages.DOCUMENTS:
                return React.createElement(Documents, null);
            case Pages.ADDRESSES:
                return React.createElement(Adresses, null);
            case Pages.PROFILE:
                return React.createElement(Profile, null);
            case Pages.QUOTATIONS:
                return React.createElement(Devis, null);
            case Pages.CREDITS:
                return React.createElement(Credits, null);
            default:
                return;
        }
    };
    useEffect(() => {
        dispatch(fetchDocumentsAttempt());
        dispatch(fetchOrdersAttempt());
        dispatch(fetchCreditsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "container navigation" },
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement("div", { className: "content" },
                    React.createElement("div", { ref: dropdownRef, className: `dropdown ${dropdownActive ? 'is-active' : ''}` },
                        React.createElement("div", { className: "dropdown-trigger", onClick: () => setDropdownActive(!dropdownActive) },
                            React.createElement("button", { className: "button", "aria-haspopup": "true", "aria-controls": "dropdown-menu4" },
                                React.createElement("span", null,
                                    React.createElement(FormattedMessage, { id: "Navigation", defaultMessage: "Navigation" })),
                                React.createElement("div", { className: "arrow" }))),
                        React.createElement("div", { onClick: () => setDropdownActive(false), className: "dropdown-menu", id: "dropdown-menu4", role: "menu" },
                            React.createElement("div", { className: "dropdown-content" },
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/profile", className: isPageActive(Pages.PROFILE) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Profil", defaultMessage: "Profil" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Changez vos infos ou acc\u00E8s", defaultMessage: "Changez vos infos ou acc\u00E8s" }),
                                            "."))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/commandes", className: isPageActive(Pages.COMMANDS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Commandes", defaultMessage: "Mes Commandes" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Historique de commandes", defaultMessage: "Historique de commandes" })))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/factures", className: isPageActive(Pages.BILLS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Factures", defaultMessage: "Mes Factures" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Historique des factures", defaultMessage: "Historique des factures" })))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/devis", className: isPageActive(Pages.QUOTATIONS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Devis", defaultMessage: "Mes Devis" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Historique des devis", defaultMessage: "Historique des devis" })))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/adresses", className: isPageActive(Pages.ADDRESSES) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Adresses", defaultMessage: "Mes Adresses" })),
                                        React.createElement("span", null,
                                            ((_a = profile === null || profile === void 0 ? void 0 : profile.addresses) === null || _a === void 0 ? void 0 : _a.length) || 0,
                                            " ",
                                            React.createElement(FormattedMessage, { id: "adresses enregistr\u00E9es", defaultMessage: "adresses enregistr\u00E9es" }),
                                            "."))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/videos", className: isPageActive(Pages.VIDEOS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Vid\u00E9os", defaultMessage: "Mes Vid\u00E9os" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Des formateurs Kust", defaultMessage: "Des formateurs Kust" }),
                                            "."))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/formations", className: isPageActive(Pages.TRAININGS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Formations", defaultMessage: "Mes Formations" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Mon calendrier", defaultMessage: "Mon calendrier" })))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/documents", className: isPageActive(Pages.DOCUMENTS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Documents", defaultMessage: "Mes Documents" })),
                                        React.createElement("span", null,
                                            documents.length || 0,
                                            " ",
                                            React.createElement(FormattedMessage, { id: "documents disponibles", defaultMessage: "documents disponibles" }),
                                            "."))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement(Link, { to: "/mon-compte/credits", className: isPageActive(Pages.CREDITS) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "Mes Avoirs", defaultMessage: "Mes Avoirs" })),
                                        React.createElement("span", null,
                                            credits.length || 0,
                                            " ",
                                            React.createElement(FormattedMessage, { id: "documents disponibles", defaultMessage: "documents disponibles" }),
                                            "."))),
                                React.createElement("div", { className: "dropdown-item" },
                                    React.createElement("div", { onClick: () => dispatch(logoutAttempt()) },
                                        React.createElement("strong", null,
                                            React.createElement(FormattedMessage, { id: "D\u00E9connexion", defaultMessage: "D\u00E9connexion" })),
                                        React.createElement("span", null,
                                            React.createElement(FormattedMessage, { id: "Merci pour votre visite !", defaultMessage: "Merci pour votre visite !" }))))))))),
            React.createElement("div", { className: "column is-auto-desktop is-full-mobile" }, showCurrentPage()))));
}
export default Navigation;
