import { Button } from "components/Form";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartInfos } from "components/Cart/CartInfos";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { selectCart } from "store/cart/cartSelectors";
import { FormattedMessage, useIntl } from "react-intl";
export function QuotationFailure() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const cart = useSelector(selectCart);
    useEffect(() => {
        if (!cart)
            dispatch(push('/'));
    }, [cart]);
    return (React.createElement("div", { className: "cart container order-success order-failure" },
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
            React.createElement(Helmet, null,
                React.createElement("title", null, "KUST | La Marque des Coiffeurs pas Comme Les Autres")),
            React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "OH NON!", defaultMessage: "OH NON!" })),
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, { id: "Une erreur s'est produite, la demande de devis n'est pas pass\u00E9e", defaultMessage: "Une erreur s'est produite, la demande de devis n'est pas pass\u00E9e" }),
                    "."),
                React.createElement(Link, { to: "/devis", className: "mt-5 is-flex is-justify-content-flex-start" },
                    React.createElement(Button, { type: "button", buttonName: intl.formatMessage({ id: "Essayer à nouveau", defaultMessage: "Essayer à nouveau" }), className: "orange" }))),
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement(CartInfos, { cart: cart },
                    React.createElement(Link, { to: "/" },
                        React.createElement(Button, { type: "button", buttonName: intl.formatMessage({ id: "Retour", defaultMessage: "Retour" }), className: "dark" })))))));
}
