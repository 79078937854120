import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectIsAuthenticated } from 'common';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import './Tabs.scss';
const TabTitle = ({ title, setSelectedTab, index, item, className }) => {
    const onClick = () => {
        setSelectedTab(index);
    };
    const isAuthenticated = useSelector(selectIsAuthenticated());
    return (React.createElement(React.Fragment, null, !!item.props.children.props.dangerouslySetInnerHTML.__html && item.props.children.props.dangerouslySetInnerHTML.__html.length > 15 &&
        React.createElement("li", { className: className }, (!isAuthenticated && index !== 0) && title !== "Ingrédients"
            ?
                React.createElement(React.Fragment, null,
                    React.createElement("button", { type: "button", className: "button tab-button is-ghost" },
                        React.createElement(FormattedMessage, { id: title, defaultMessage: title }),
                        " ",
                        React.createElement(FontAwesomeIcon, { icon: faLock })))
            :
                React.createElement("button", { type: "button", className: "button tab-button", onClick: onClick },
                    React.createElement(FormattedMessage, { id: title, defaultMessage: title })))));
};
export default TabTitle;
