import { haveFreeProducts, isCartProductValid, isStockValid, BusinessType } from "common";
import { Button } from "components/Form";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
export function AddToCartButton({ product, toggleModal, isAuthenticated, editMode, prod }) {
    const profile = useSelector(selectProfileInfos);
    const intl = useIntl();
    const getButton = () => {
        var _a, _b;
        if (!isAuthenticated) {
            return React.createElement(Button, { buttonName: intl.formatMessage({ id: "Connexion", defaultMessage: "Connexion" }), className: "orange", type: "button", onClick: () => toggleModal() });
        }
        if (isAuthenticated && (!((_a = product.businessType) === null || _a === void 0 ? void 0 : _a.includes(BusinessType.B2C)) && (profile === null || profile === void 0 ? void 0 : profile.businessType) === BusinessType.B2C) || (!((_b = product.businessType) === null || _b === void 0 ? void 0 : _b.includes(BusinessType.B2B)) && (profile === null || profile === void 0 ? void 0 : profile.businessType) === BusinessType.B2B)) {
            return React.createElement(Button, { buttonName: intl.formatMessage({ id: "Produit indisponible", defaultMessage: "Produit indisponible" }), className: "orange", disabled: true });
        }
        return React.createElement(Button, Object.assign({}, (haveFreeProducts(product) ? {
            onClick: () => toggleModal(),
            type: "button"
        } : {
            type: "submit"
        }), { buttonName: !isStockValid(product)
                ? intl.formatMessage({ id: "Produit indisponible", defaultMessage: "Produit indisponible" })
                : editMode ? intl.formatMessage({ id: "Modifier le produit", defaultMessage: "Modifier le produit" }) : intl.formatMessage({ id: "Ajouter le produit", defaultMessage: "Ajouter le produit" }), className: "orange", disabled: !isCartProductValid(product, prod) }));
    };
    return (React.createElement("div", null, getButton()));
}
