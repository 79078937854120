import { BusinessType, convertToEuros, getChoicesQuantity, logoutAttempt, ProductType } from "common";
import { Angle } from "components/Angle/Angle";
import { Button } from "components/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStep from "shared/hooks/useStep";
import { fetchCreditsAttempt } from "store/account/credits/creditsActions";
import { selectCredits } from "store/account/credits/creditsSelectors";
import { FormattedMessage, useIntl } from "react-intl";
function Credits() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useStep();
    const credits = useSelector(selectCredits);
    useEffect(() => {
        dispatch(fetchCreditsAttempt());
    }, [dispatch]);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos avoirs", defaultMessage: "Vos avoirs" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }))),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "commandes" },
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { id: "Historique de mes avoirs", defaultMessage: "Historique de mes avoirs" }),
                    "."),
                React.createElement("div", { className: "orders-list" }, credits.length > 0 && credits.map((credit) => {
                    var _a;
                    return React.createElement("div", { key: credit.billingId, className: `order-item ${activeStep === +credit.billingId ? 'open' : ''}` },
                        React.createElement("div", { onClick: () => setActiveStep(+credit.billingId), className: "order-toggle" },
                            credit.createdAt,
                            " - ",
                            credit.billingId),
                        React.createElement("div", { className: "order-divider" },
                            React.createElement(Angle, { isActive: activeStep === +(credit === null || credit === void 0 ? void 0 : credit.billingId), setActiveStep: setActiveStep, step: +credit.billingId })),
                        React.createElement("div", { className: "order-content" }, credit.products.length
                            ? React.createElement("div", { className: "column is-mobile" },
                                React.createElement("div", { className: "order-list" },
                                    credit.products.map((product, index) => {
                                        return (React.createElement("div", { className: "order-list-item", key: index },
                                            React.createElement("span", { className: product.expired ? 'expired' : '' },
                                                product.name,
                                                " x ",
                                                product.quantity),
                                            React.createElement("span", null,
                                                (convertToEuros(credit.businessType === BusinessType.B2B ? product.price : product.b2cPrice
                                                    * (product.type === ProductType.CHOICE_PRODUCT && !product.restricted || product.type === ProductType.SCALE_GROUP ? getChoicesQuantity(product) : 1)
                                                    * product.quantity)),
                                                "\u20AC")));
                                    }),
                                    React.createElement("div", { className: "order-list-item" },
                                        React.createElement("span", null,
                                            React.createElement("strong", null,
                                                React.createElement(FormattedMessage, { id: "Total", defaultMessage: "Total" }))),
                                        React.createElement("span", null,
                                            React.createElement("strong", null,
                                                convertToEuros(credit.total),
                                                "\u20AC ",
                                                React.createElement(FormattedMessage, { id: "HT", defaultMessage: "HT" }))))))
                            : React.createElement("div", null,
                                React.createElement(FormattedMessage, { id: "Aucun devis pour le moment", defaultMessage: "Aucun devis pour le moment" }))),
                        React.createElement("div", { className: "order-content-item columns bill" },
                            React.createElement("div", { className: "column is-full" }, ((_a = credit === null || credit === void 0 ? void 0 : credit.credit) === null || _a === void 0 ? void 0 : _a.viewUrl) !== undefined
                                ?
                                    React.createElement("a", { href: credit.credit.viewUrl, target: "_blank" },
                                        React.createElement(Button, { className: "black-white", buttonName: intl.formatMessage({ id: "Télécharger mon avoir", defaultMessage: "Télécharger mon avoir" }) }))
                                : '')));
                }))))));
}
export default Credits;
