import React from "react";
import { Step } from "./Step";
import "./AddressStep.scss";
import { AddressItem } from "./AddressItem";
import { FormattedMessage } from "react-intl";
export function AddressStep({ name, title, disableCurrentStep, selectAddress, addresses, step, activeStep, activeOrderStep, setActiveOrderStep, children, }) {
    return (React.createElement("div", { className: "white-block addresses-step" },
        React.createElement(Step, { step: step, disabled: disableCurrentStep, activeStep: activeOrderStep, setActiveStep: setActiveOrderStep, title: title },
            React.createElement("div", null,
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "S\u00E9lectionnez une de vos adresses ou enregistrez une nouvelle ci-dessous :", defaultMessage: "S\u00E9lectionnez une de vos adresses ou enregistrez une nouvelle ci-dessous :" })),
                children[0],
                addresses.map((address) => {
                    return React.createElement("div", { key: address._id, className: "billings" },
                        React.createElement(AddressItem, { activeStep: activeStep, selectAddress: selectAddress, address: address, name: name }));
                })),
            children[1])));
}
