import React from "react";
import "./Auth.scss";
import Register from '../Register/Register';
import Login from '../Login/Login';
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
function Auth() {
    return (React.createElement("div", { className: "auth container" },
        React.createElement(Helmet, null,
            React.createElement("title", null, "Inscription & Connexion - KUST | La Marque des Coiffeurs pas Comme Les Autres\"")),
        React.createElement("div", { className: "columns is-multiline is-mobile is-centered" },
            React.createElement("div", { className: "column is-one-quarter black-block" },
                React.createElement("div", { className: "content" },
                    React.createElement(Login, null))),
            React.createElement("div", { className: "column is-half-desktop is-full-mobile" },
                React.createElement("div", { className: "before-white" },
                    React.createElement("h1", null,
                        React.createElement(FormattedMessage, { id: "Nouveau client", defaultMessage: "Nouveau client" })),
                    React.createElement("p", null,
                        React.createElement(FormattedMessage, { id: "Inscrivez-vous en compl\u00E9tant les champs ci-dessous :", defaultMessage: "Inscrivez-vous en compl\u00E9tant les champs ci-dessous :" }))),
                React.createElement("div", { className: "white-block" },
                    React.createElement(Register, null))))));
}
export default Auth;
