import { logoutAttempt } from 'common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocumentAttempt } from 'store/account/documents/documentActions';
import { selectDocument } from 'store/account/documents/documentSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from "react-intl";
import "./Documents.scss";
import { Button } from 'components/Form';
function Documents() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const documents = useSelector(selectDocument);
    return (React.createElement("div", { className: "profile" },
        React.createElement("div", { className: "before-white" },
            React.createElement("div", null,
                React.createElement("h1", null,
                    React.createElement(FormattedMessage, { id: "Vos documents", defaultMessage: "Vos documents" })),
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "Bienvenue dans votre espace personnel KUST !", defaultMessage: "Bienvenue dans votre espace personnel KUST !" }))),
            React.createElement(Button, { buttonName: intl.formatMessage({ id: "Déconnexion", defaultMessage: "Déconnexion" }), onClick: () => dispatch(logoutAttempt()), className: "black-white" })),
        React.createElement("div", { className: "white-block" },
            React.createElement("div", { className: "documents container" }, (documents === null || documents === void 0 ? void 0 : documents.length) > 0 &&
                React.createElement("div", null, documents.map((document) => React.createElement("div", { className: "document-item", key: document === null || document === void 0 ? void 0 : document._id },
                    React.createElement("div", null,
                        React.createElement("strong", null, document.name),
                        React.createElement("p", null, document.description),
                        React.createElement("p", { className: "format" },
                            React.createElement(FormattedMessage, { id: "Format pdf", defaultMessage: "Format .pdf" }))),
                    React.createElement("div", null,
                        React.createElement("button", { onClick: () => dispatch(downloadDocumentAttempt(document._id)) },
                            React.createElement(FormattedMessage, { id: "Voir", defaultMessage: "Voir" }),
                            " ",
                            React.createElement(FontAwesomeIcon, { icon: faEye }))))))),
            (documents === null || documents === void 0 ? void 0 : documents.length) === 0 &&
                React.createElement("div", null,
                    React.createElement(FormattedMessage, { id: "Il n'y a pas de documents disponibles pour le moment", defaultMessage: "Il n'y a pas de documents disponibles pour le moment" }),
                    "."))));
}
export default Documents;
