import { Step } from 'containers/Order/components/Step';
import { AddressItem } from 'containers/Quotation/components/AddressItem';
import React from 'react';
import { FormattedMessage } from "react-intl";
export function Address({ name, title, addresses, activeStep, activeQuotationStep, disableCurrentStep, step, setActiveQuotationStep, selectAddress, children, }) {
    return (React.createElement("div", { className: "white-block addresses-step" },
        React.createElement(Step, { step: step, disabled: disableCurrentStep, activeStep: activeQuotationStep, setActiveStep: setActiveQuotationStep, title: title },
            React.createElement("div", null,
                React.createElement("p", null,
                    React.createElement(FormattedMessage, { id: "S\u00E9lectionnez une de vos adresses ou enregistrez une nouvelle ci-dessous :", defaultMessage: "S\u00E9lectionnez une de vos adresses ou enregistrez une nouvelle ci-dessous :" })),
                children,
                addresses.map((address) => {
                    return React.createElement("div", { key: address._id, className: "billings" },
                        React.createElement(AddressItem, { activeStep: activeStep, selectAddress: selectAddress, address: address, name: name }));
                })))));
}
