import React, { useEffect, useState } from 'react';
import TabTitle from './TabTitle';
import "./Tabs.scss";
import { useHistory } from 'react-router-dom';
const Tabs = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const getHashFromUrl = () => {
            const hash = window.location.hash;
            return hash ? hash.substring(1) : '';
        };
        const unlisten = history.listen(() => {
            if (getHashFromUrl() === 'tarifs') {
                setSelectedTab(children.length - 1);
            }
        });
        return () => {
            unlisten();
        };
    }, [history]);
    return (React.createElement("div", null,
        React.createElement("ul", { className: "columns tabs" }, children.filter(c => !!c.props).map((item, index) => (React.createElement(TabTitle, { key: index, title: item.props.title, index: index, item: item, className: `${item.props.className} ${(selectedTab === index) ? "active" : ""}`, setSelectedTab: setSelectedTab })))),
        children.filter(c => !!c.props)[selectedTab]));
};
export default Tabs;
