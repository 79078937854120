import { selectErrors } from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropdown } from "shared/hooks/useDropdown";
import { fetchTagsAttempt, setActiveTag } from "store/tags/tagActions";
import { selectTags } from "store/tags/tagSelectors";
import { ProductFilter } from "../components/ProductFilter";
import "./ProductsFilters.scss";
import { FormattedMessage } from "react-intl";
export function ProductsFilters() {
    const dispatch = useDispatch();
    const { dropdownRef, dropdownActive, setDropdownActive } = useDropdown();
    const apiErrors = useSelector(selectErrors('FETCH_TAGS'));
    const tags = useSelector(selectTags).filter((tag) => !!tag.isDisplayable);
    useEffect(() => {
        dispatch(fetchTagsAttempt());
    }, [dispatch]);
    const toggleTag = (tagId = '') => {
        dispatch(setActiveTag(tagId));
        setDropdownActive(false);
    };
    return (React.createElement("div", { ref: dropdownRef, className: `dropdown ${dropdownActive ? 'is-active' : ''}` },
        React.createElement("div", { onClick: () => setDropdownActive(!dropdownActive), className: "dropdown-trigger" },
            React.createElement("button", { className: "button", "aria-haspopup": "true", "aria-controls": "dropdown-menu4" },
                React.createElement("span", { id: "filter" },
                    React.createElement(FormattedMessage, { id: "Filtrer les produits", defaultMessage: "Filtrer les produits" })),
                React.createElement("span", { className: "icon is-small" },
                    React.createElement("i", { className: "fas fa-angle-down", "aria-hidden": "true" })))),
        React.createElement("div", { className: "dropdown-menu", id: "dropdown-menu4", role: "menu" },
            React.createElement("div", { className: "dropdown-content" },
                React.createElement("div", { className: "dropdown-item" },
                    React.createElement(ProductFilter, { onClick: () => toggleTag(), defaultChecked: true, label: "Tous" })),
                tags.map((tag) => React.createElement("div", { key: tag._id, className: "dropdown-item" },
                    React.createElement(ProductFilter, { onClick: () => toggleTag(tag._id), label: tag.name })))))));
}
