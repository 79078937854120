import React from "react";
import { useDispatch } from "react-redux";
import { GoogleLoginButton, AppleLoginButton, FacebookLoginButton } from 'react-social-login-buttons';
import { LoginSocialApple, LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { authAppleAttempt, authFacebookAttempt, authGoogleAttempt } from "store/account/profile/profileActions";
import { getEnvVar } from 'common';
function Socials() {
    const dispatch = useDispatch();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "socials" },
            React.createElement(LoginSocialFacebook, { appId: getEnvVar('RAZZLE_FB_APP_ID'), onResolve: ({ provider, data }) => {
                    dispatch(authFacebookAttempt(data));
                }, onReject: (err) => {
                    console.log(err);
                } },
                React.createElement(FacebookLoginButton, null,
                    React.createElement("hr", null))),
            React.createElement(LoginSocialApple, { client_id: getEnvVar('RAZZLE_APPLE_ID'), scope: 'name email', redirect_uri: getEnvVar('RAZZLE_FRONT_URL') + '/authentification', onResolve: ({ provider, data }) => {
                    dispatch(authAppleAttempt(data === null || data === void 0 ? void 0 : data.authorization));
                }, onReject: err => {
                    console.log(err);
                } },
                React.createElement(AppleLoginButton, { className: "apple-login" },
                    React.createElement("hr", null))),
            React.createElement(LoginSocialGoogle, { typeResponse: "idToken", auto_select: true, client_id: getEnvVar('RAZZLE_GG_APP_ID'), onResolve: ({ provider, data }) => {
                    dispatch(authGoogleAttempt(data));
                }, onReject: (err) => {
                    console.log(err);
                } },
                React.createElement(GoogleLoginButton, null,
                    React.createElement("hr", null)))),
        React.createElement("br", null),
        React.createElement("strong", null, "OU")));
}
export default Socials;
