import { ProductType, isChoicesValid, isElementValid, isQuantityValid, haveFreeProducts } from "common";
const pack = {
    message: 'Je choisis ma quantité',
    description: 'Choisissez votre quantité de produit ci-dessus'
};
const choicePack = {
    message: 'Je choisis ma quantité',
    description: 'Sélectionnez votre pack ci-dessus pour sélectionner vos nuances La couleur des nuances est présentée à titre indicatif Elle ne peut être considérée comme exacte dans la réalité'
};
const choices = {
    message: 'Je choisis mes produits',
    description: 'Selectionnez vos produits ci-dessous, à l\’aide des boutons + et - ou en inscrivant la valeur souhaitée.'
};
const freeProduct = {
    message: 'J\'ajoute au panier et je profite de produits offerts',
    description: 'Vous pouvez à présent ajouter vos produits au panier afin de bénéficier de produits offerts'
};
const withoutFreeProduct = {
    message: 'J\'ajoute au panier',
    description: 'Vous pouvez à présent ajouter vos produits au panier'
};
const simpleProductSteps = {
    message: 'Je choisis ma quantité',
    description: 'Sélectionnez votre quantité de produit ci-dessus'
};
const choiceGroupSteps = [
    choicePack,
    choices
];
export const getProductsSteps = (product) => {
    const steps = [];
    switch (product === null || product === void 0 ? void 0 : product.type) {
        case ProductType.CHOICE_GROUP:
            steps.push(...choiceGroupSteps);
            break;
        case ProductType.SCALE_GROUP:
        case ProductType.CHOICE_PRODUCT:
            steps.push(choices);
            break;
        case ProductType.SIMPLE_GROUP:
            steps.push(pack);
            break;
        case ProductType.SIMPLE_PRODUCT:
            steps.push(simpleProductSteps);
            break;
    }
    steps.push(haveFreeProducts(product) ? freeProduct : withoutFreeProduct);
    return steps;
};
export const getActiveStep = (product) => {
    var _a;
    switch (product === null || product === void 0 ? void 0 : product.type) {
        case ProductType.CHOICE_GROUP:
            if (isElementValid(product) && isChoicesValid(product, (_a = product.element) === null || _a === void 0 ? void 0 : _a.count))
                return 3;
            if (isElementValid(product))
                return 2;
            break;
        case ProductType.SCALE_GROUP:
        case ProductType.CHOICE_PRODUCT:
            if (isChoicesValid(product, product.count))
                return 2;
            break;
        case ProductType.SIMPLE_GROUP:
            if (isElementValid(product))
                return 2;
            break;
        case ProductType.SIMPLE_PRODUCT:
            if (isQuantityValid(product))
                return 2;
            break;
        default:
            return 1;
    }
    return 1;
};
